import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-dropdown-select";
import DatePicker from "react-datepicker";

import Calander from "../../../../assets/Icon/calendar.svg";
import { Avatar } from "@mui/material";
import LeftArrow from "../../../../assets/Icon/chevron-left.svg";
import RightArrow from "../../../../assets/Icon/chevron-right.svg";
import TagButton from "../../TagButton";
import Search from "../../../../assets/Icon/Search.svg";
import close from "../../../../assets/Icon/close.svg";
import {
  browneTagStyle,
  greenTagStyle,
  orangeTagStyle,
  redTagStyle,
} from "../../TagButtonStyle";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ApiCalling from "../../../../network/ApiCalling";
import { imageStudentList } from "../../../CommonImage";
import restore from "../../../../assets/Icon/restore.svg";
import moment from "moment/moment";
import Util from "../../../../Util";
import { Modal } from "react-bootstrap";
import ToastHOC from "../../../HOC/ToastHOC";
import ModalComponent from "../../ModalComponent";
import * as XLSX from "xlsx/xlsx.mjs";
import Loader from "../../../../components/common/Loader";
import Sort from "../../../../assets/Icon/Short.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Close from "../../../../assets/Icon/close.png";
import { setHours, setMinutes } from "date-fns";

const AttendanceLog = (props) => {
  const { setIsToast } = props;
  const inputRef = useRef(null);
  const getLanguage = Util.getSelectedLanguage();
  const history = useHistory();
  const { t } = useTranslation();
  const school = Util.getSelectedSchool();
  const [singleStudentId, setSingleStudentId] = useState(0);
  const [selectedStudentId, setSelectedStudentId] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [date, setDate] = useState(new Date());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [classList, setClassList] = useState([]);
  const [classId, setClassId] = useState(0);
  const [timingList, setTimingList] = useState([]);
  const [timingId, setTimingId] = useState(0);
  const [attendanceStatus, setAttendanceStatus] = useState(4);
  const [alertModal, setAlertModal] = useState(false);
  const [checkInError, setCheckInError] = useState(false);
  const [checkOutError, setCheckOutError] = useState(false);
  const [absentError, setAbsentError] = useState(false);
  const [leaveError, setLeaveError] = useState(false);
  const [checkInModal, setCheckInModal] = useState(false);
  const [checkOutModal, setCheckOutModal] = useState(false);
  const [absentModal, setAbsentModal] = useState(false);
  const [checkInTime, setCheckInTime] = useState(new Date());
  const [checkInTimeError, setCheckInTimeError] = useState("");
  const [checkOutTimeError, setCheckOutTimeError] = useState("");
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [allStudentData, setAllStudentData] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [attendanceId, setAttendanceId] = useState(0);
  const [resetModal, setResetModal] = useState(false);
  const [daysPerWeek, setDaysPerWeek] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [daysPerWeek1, setDaysPerWeek1] = useState([]);

  const [resetMultipleStudentsModal, setResetMultipleStudentsModal] =
    useState(false);
  const [selectedClass, setSelectedClass] = useState({
    id: classId,
    name: "All Classes",
  });
  const [selectedTiming, setSelectedTiming] = useState({
    id: timingId,
    title: "All Timing",
  });
  const [selectedAttendanceStatus, setSelectedAttendanceStatus] = useState({
    value: attendanceStatus,
    title: "All Attendance",
  });
  const [isNotAcademicYear, setIsNotAcademicYear] = useState(false);
  const [academicStartDate, setAcademicStartDate] = useState(null);
  const [academicEndDate, setAcademicEndDate] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const checkMediaQuery = () => {
    const mediaQuery = window.matchMedia("(max-width: 1324px)");
    setIsSmallScreen(mediaQuery.matches);
  };
  useEffect(() => {
    checkMediaQuery(); // Initial check

    const handleResize = () => {
      checkMediaQuery(); // Check when the window is resized
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const DaysList = [
    // { value: "all", name: "All" },
    // { value: "", name: "Days Per Week" },
    { value: "Monday", name: "Monday" },
    { value: "Tuesday", name: "Tuesday" },
    { value: "Wednesday", name: "Wednesday" },
    { value: "Thursday", name: "Thursday" },
    { value: "Friday", name: "Friday" },
  ];

  const customRenderer = ({ props, state, methods }) => {
    const { values } = state;
    const selectedDays = values
      .map((value) => value.name.substring(0, 2))
      .join(", ");
    const hasSelectedValues = selectedDays.length > 0;
    console.log(hasSelectedValues, selectedDays)
    return (
      <>
        <div
          className={` ${
            hasSelectedValues
              ? "react-dropdown-multi-selected"
              : "selected-weekDays"
          }`}
        >
          {selectedDays || props.placeholder}
          {hasSelectedValues && (
            <span
              className={`ml-2`}
              onClick={() => {
                methods.clearAll();
              }}
            >
              ×
            </span>
          )}
        </div>
      </>
    );
  };

  const getClassList = async () => {
    ApiCalling.apiCallBodyDataGet("classroom/list").then((res) => {
      const modifiedClassList = res.data.map((grade) => ({
        id: grade.id,
        name: `${grade.Grade.title}-${grade.name}`,
      }));
      setClassList(modifiedClassList);
      setTimeout(() => {}, 1000);
    });
  };

  const getTimingList = () => {
    ApiCalling.apiCallBodyDataGet("timing/list").then((res) => {
      setTimingList(res.data);
    });
  };

  const AttendanceList = [
    { value: 4, title: "All Attendance" },
    { value: 0, title: "Absent" },
    { value: 1, title: "Present" },
    { value: 2, title: "Leave" },
    { value: 3, title: "Pending" },
  ];

  const weekdays = ["M", "TU", "W", "TH", "F"];

  const options = [
    { value: "", name: "Count Per Week" },
    ...Array.from({ length: 6 }, (_, i) => ({
      value: i,
      name: i,
    })),
  ];

  const CircleLetter = ({ letter, isTrue }) => {
    const circleClassName = `circle ${isTrue ? "circle-checked" : ""}`;
    const letterClassName = `letter ${isTrue ? "letter-checked" : ""}`;
    return (
      <div className={circleClassName}>
        <object className={letterClassName}>{letter[0]}</object>
      </div>
    );
  };
  const changeClassRoom = (data) => {
    setSelectedStudentId([]);
    setSelectedStudent([]);
    setCurrentPage(1);
    setClassId(data[0].id);
  };
  const changeTiming = (data) => {
    console.log(data);
    setSelectedStudentId([]);
    setSelectedStudent([]);
    setCurrentPage(1);
    setTimingId(data[0].id);
  };

  const changeStatus = (data) => {
    setSelectedStudentId([]);
    setSelectedStudent([]);
    setCurrentPage(1);
    setAttendanceStatus(data[0].value);
  };
  const changeDate = (selectedDate) => {
    setDate(selectedDate);
    setIsDatePickerOpen(false);
  };
  const searchClicked = () => {
    if (search.length > 0) {
      setSearch("");
      setSearchClick("");
    } else {
      setCurrentPage(1);
      setSearchClick(search);
    }
  };
  const EnterHandler = (event) => {
    if (event.key === "Enter") {
      setSearchClick(search);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };
  const handleAllSelectedStudent = (e) => {
    if (e.target.checked) {
      setSelectedStudentId(studentList.map((item) => item.id));
      setSelectedStudent(studentList);
    } else {
      setSelectedStudentId([]);
      setSelectedStudent([]);
    }
  };

  const StudentChecked = (event) => {
    const id = Number(event.target.value);
    const student = studentList.find((item) => item.id === id);
    if (event.target.checked) {
      setSelectedStudentId([...selectedStudentId, id]);
      setSelectedStudent([...selectedStudent, student]);
    } else {
      const students = selectedStudent.filter((s) => s !== student);
      const data = selectedStudentId.filter((s) => s !== id);
      setSelectedStudentId(data);
      setSelectedStudent(students);
    }
  };
  const currentDate = new Date();

  const handleNextClick = () => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    setDate(newDate);
    setSelectedStudentId([]);
    setSelectedStudent([]);
  };

  const handlePrevClick = () => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 1);
    setDate(newDate);
    setSelectedStudentId([]);
    setSelectedStudent([]);
  };
  const academicYearList = async () => {
    ApiCalling.apiCallBodyDataGet("academic-year/getActiveOne")
      .then((res) => {
        // console.log("List Academic Year Name", res.data);
        if (res.data) {
          setIsNotAcademicYear(false);
          setAcademicStartDate(res.data.start_on);
          setAcademicEndDate(res.data.end_on);
        } else {
          setIsNotAcademicYear(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllStudentList = () => {
    setIsLoading(true);
    const data = {
      str_search: searchClick,
      classroom_id: classId,
      page_size: recordPerPage,
      page_number: currentPage,
      date: moment(date).format("YYYY-MM-DD"),
      status: attendanceStatus,
      timing_id: timingId,
      ascdesc: sortOrder ? "desc" : "asc",
      order_by: sortBy,
      weekdays: daysPerWeek1.map((d) => d.value).join(","),
    };
    ApiCalling.apiCallBodyDataPost("student/get-attendance", data)
      .then((res) => {
        setIsLoading(false);
        setStudentList(res.data.response || []);
        setOriginalData(res.data.response || []);
        setTotalPages(res.data.total_pages);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const student = () => {
    const data = {
      str_search: searchClick,
      classroom_id: classId,
      page_size: recordPerPage,
      page_number: currentPage,
      date: date,
      status: attendanceStatus,
      timing_id: timingId,
      weekdays: daysPerWeek1.map((d) => d.value).join(","),
    };
    ApiCalling.apiCallBodyDataPost("student/get-attendance", data)
      .then((res) => {
        setAllStudentData(res.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const exportToExcel = () => {
    const dataForExport = allStudentData.map((stu) => ({
      date: moment(date).format("DD/MM/YYYY"),
      StudentID: stu.id,
      FirstName: stu.first_name,
      LastName: stu.last_name,
      Timing: Util.isValidData(stu.timing) ? stu.timing : "-",
      CheckIn: Util.isValidData(stu.attendance_checkin_time)
        ? moment(stu.attendance_checkin_time).format("hh:mm a")
        : "-",
      CheckOut: Util.isValidData(stu.attendance_checkout_time)
        ? moment(stu.attendance_checkout_time).format("hh:mm a")
        : "-",
      Status:
        stu.attendance_status === 0
          ? "Absent"
          : stu.attendance_status === 1
          ? "Present"
          : "Pending",
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataForExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Student List");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const newTab = window.open(url, "_blank");

    newTab.addEventListener("load", () => {
      URL.revokeObjectURL(url);
    });
  };
  const handleAbsentStudent = () => {
    const selectedStudentIds = selectedStudentId.join(",");
    const data = {
      date: date,
      student_ids: selectedStudentIds,
    };
    ApiCalling.apiCallBodyDataPost("daily-attendance/mark-absent", data)
      .then((res) => {
        setAbsentModal(false);
        setIsToast(res.data.message);
        getAllStudentList();
        setSelectedStudentId([]);
        setSelectedStudent([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCheckInTime = () => {
    setCheckInTimeError("");
    let error = false;
    if (checkInTime === null) {
      setCheckInTimeError(t("check-in time is required"));
      error = true;
    }
    if (!error) {
      const selectedStudentIds = selectedStudentId.join(",");
      // console.log("selectedStudentId", selectedStudentId);
      const data = {
        date: date,
        student_ids:
          selectedStudentId.length > 0
            ? selectedStudentIds
            : singleStudentId.toString(),
        checkin_time: checkInTime,
      };
      ApiCalling.apiCallBodyDataPost("daily-attendance/check-in", data)
        .then((res) => {
          setCheckInModal(false);
          setIsToast(res.data.message);
          getAllStudentList();
          setCheckInTimeError("");
          setSelectedStudentId([]);
          setSelectedStudent([]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleCheckOutTime = () => {
    setCheckOutTimeError("");
    let error = false;
    if (checkOutTime === null) {
      setCheckOutTimeError(t("check-out time is required"));
      error = true;
    }
    if (!error) {
      const selectedStudentIds = selectedStudentId.join(",");
      const data = {
        date: date,
        student_ids:
          selectedStudentId.length > 0
            ? selectedStudentIds
            : singleStudentId.toString(),
        check_out: checkOutTime,
      };
      ApiCalling.apiCallBodyDataPost("daily-attendance/check-out", data)
        .then((res) => {
          setCheckOutModal(false);
          setIsToast(res.data.message);
          getAllStudentList();
          setCheckOutTimeError("");
          setSelectedStudentId([]);
          setSelectedStudent([]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleReset = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`daily-attendance/delete/${attendanceId}`)
      .then((res) => {
        setIsLoading(false);
        setIsToast(res.data.message);
        getAllStudentList();
        setResetModal(false);
        setAttendanceId(0);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleResetMultipleStudent = () => {
    const studentIds = selectedStudent
      .filter((s) => s.attendance_id !== null)
      .map((s) => s.attendance_id)
      .join(",");

    const data = {
      ids: studentIds,
    };
    ApiCalling.apiCallBodyDataPost("daily-attendance/reset-many", data)
      .then((res) => {
        setIsToast(res.data.message);
        getAllStudentList();
        setResetMultipleStudentsModal(false);
        setSelectedStudentId([]);
        setSelectedStudent([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (isNotAcademicYear == false) {
      getAllStudentList();
      student();
    }
  }, [
    recordPerPage,
    currentPage,
    searchClick,
    date,
    sortBy,
    sortOrder,
    classId,
    timingId,
    attendanceStatus,
    daysPerWeek1,
  ]);

  // useEffect(() => {
  //   if (isNotAcademicYear == false) {
  //     student();
  //   }
  // }, [
  //    daysPerWeek1,
  // ]);

  useEffect(() => {
    if (isNotAcademicYear == false) {
      getClassList();
      getTimingList();
    }
  }, []);
  useEffect(() => {
    academicYearList();
  }, []);
  const datePickerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsDatePickerOpen(false);
    }
  };
  useEffect(() => {
    if (isNotAcademicYear == false) {
      if (isDatePickerOpen) {
        document.addEventListener("click", handleClickOutside);
      } else {
        document.removeEventListener("click", handleClickOutside);
      }

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [isDatePickerOpen]);

  const changeDaysPerWeek = (data) => {
    setCurrentPage(1);
    setDaysPerWeek(data);
    setIsLoading(true);
    if (data[0]?.value === "") {
      setStudentList(originalData);
      setIsLoading(false);
    } else {
      originalData.map((item, index) => {
        const dayBooleanValues = {
          M: item.student_monday?.data[0],
          TU: item.student_tuesday?.data[0],
          W: item.student_wednesday?.data[0],
          TH: item.student_thursday?.data[0],
          F: item.student_friday?.data[0],
        };
        item.dayBooleanValues = dayBooleanValues;
        item.count = Object.values(dayBooleanValues).filter(
          (value) => value === 1
        ).length;
        return item;
      });
      const filteredData = originalData.filter((item) => {
        return item.count === data[0]?.value;
      });
      setStudentList(filteredData);
      setIsLoading(false);
    }
  };

  const changeDaysPerWeek1 = (data) => {
    console.log(data);
    setCurrentPage(1);
    setDaysPerWeek1(data);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="body">
          <div className="p-3">
            {!isNotAcademicYear ? (
              <>
                {!isLoading ? (
                  <>
                    <div className="row py-3">
                      <div
                        className={` col-md-10 col-sm-12 d-flex flex-wrap align-items-center ${
                          isSmallScreen ? "col-lg-8" : "col-lg-6"
                        }`}
                      >
                        <h3 className="page-head">{t("Attendance Log")}</h3>
                        <span
                          style={{
                            width: "1px",
                            height: "25px",
                            backgroundColor: "#E5E7EB",
                            marginLeft: "10px",
                            marginRight: "5px",
                            display: "inline-block",
                          }}
                        ></span>
                        <div
                          className="date-pick-att d-flex align-items-center mx-1"
                          style={{ position: "relative" }}
                        >
                          <img
                            src={Calander}
                            alt=""
                            className="mr-2"
                            style={{ cursor: "pointer" }}
                            onClick={toggleDatePicker}
                          />
                          <div
                            className="custom-date-style mr-1"
                            onClick={toggleDatePicker}
                          >
                            {moment(date).format(
                              Util.getMomentDateTimeFormat(school.date_format)
                            )}
                          </div>
                          <DatePicker
                            ref={datePickerRef}
                            wrapperClassName="custom-date-picker-style sr-only "
                            showPopperArrow={true}
                            placeholderText="Select Date"
                            selected={date}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={changeDate}
                            className="form-control "
                            popperPlacement="bottom"
                            minDate={new Date(academicStartDate)}
                            maxDate={new Date(academicEndDate)}
                            open={isDatePickerOpen}
                            dateFormat={school.date_format}
                            shouldCloseOnSelect={true}
                            onClickOutside={() => setIsDatePickerOpen(false)}
                          />
                        </div>
                        {/* June 21, 2023 */}

                        <div className="d-flex  flex-nowrap align-items-center">
                          <button
                            className="left-arrow-btn mr-1"
                            onClick={handlePrevClick}
                          >
                            <img src={LeftArrow} alt="" />
                          </button>
                          <button
                            className="right-arrow-btn ml-0"
                            onClick={handleNextClick}
                            disabled={
                              moment(date).format("DD/MM/YYYY") >=
                              moment(currentDate).format("DD/MM/YYYY")
                            }
                          >
                            <img src={RightArrow} alt="" />
                          </button>
                        </div>
                      </div>

                      <div
                        className={` col-md-10  offset-lg-0 offset-md-2 mt-lg-0  mt-md-3 col-sm-12 text-md-right text-sm-center chek-in-out-btn ${
                          isSmallScreen
                            ? "col-lg-8 offset-lg-4 mt-lg-2"
                            : "col-lg-6"
                        }`}
                      >
                        <div className="d-flex  align-items-center justify-content-end">
                          {selectedStudentId.length > 0 && (
                            <>
                              <button
                                className="btn btn-lg text-nowrap custom-secondary border-primary"
                                onClick={() => {
                                  const checkIn = selectedStudent.some(
                                    (d) =>
                                      d.attendance_status === "0" ||
                                      d.attendance_status === 0 ||
                                      d.attendance_status === 2
                                  );
                                  if (checkIn) {
                                    setCheckInError(checkIn);
                                    setAlertModal(true);
                                  } else {
                                    setCheckInModal(true);
                                  }
                                }}
                              >
                                {t("Check-in all")}
                              </button>
                              <button
                                className="btn btn-lg text-nowrap custom-secondary ml-1 border"
                                onClick={() => {
                                  const checkOut = selectedStudent.some(
                                    (d) =>
                                      d.attendance_status === "0" ||
                                      d.attendance_status === 0 ||
                                      d.attendance_status === null ||
                                      d.attendance_checkin_time === null ||
                                      d.attendance_status === 2
                                  );
                                  if (checkOut) {
                                    setCheckOutError(checkOut);
                                    setAlertModal(true);
                                  } else {
                                    setCheckOutModal(true);
                                  }
                                }}
                              >
                                {t("Check-out all")}
                              </button>
                              <button
                                className="btn btn-lg text-nowrap custom-secondary ml-1 border"
                                onClick={() => {
                                  const absent = selectedStudent.some(
                                    (d) =>
                                      d.attendance_status === 1 ||
                                      d.attendance_status === "1" ||
                                      d.attendance_status === 0 ||
                                      d.attendance_status === "0" ||
                                      d.attendance_status === 2
                                  );
                                  if (absent) {
                                    setAbsentError(absent);
                                    setAlertModal(true);
                                  } else {
                                    setAbsentModal(true);
                                  }
                                }}
                              >
                                {t("Absent all")}
                              </button>
                              <button
                                className="btn btn-lg text-nowrap custom-secondary ml-1 mr-1 border"
                                onClick={() => {
                                  const leave = selectedStudent.some(
                                    (d) => d.attendance_status === 2
                                  );
                                  if (leave) {
                                    setLeaveError(leave);
                                    setAlertModal(true);
                                  } else {
                                    setResetMultipleStudentsModal(true);
                                  }
                                }}
                              >
                                {t("Reset all")}
                              </button>

                              <span className="before-line"></span>
                            </>
                          )}

                          <button
                            className="btn btn-lg text-nowrap custom-primary"
                            type="button"
                            onClick={exportToExcel}
                            disabled={studentList.length == 0}
                          >
                            {t("Export Excel")}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-div">
                      <div className="d-flex gap-20 align-items-center table-sort-bar">
                        <div className="custom-search-wrap">
                          <div className="input-group">
                            <input
                              className="form-control search"
                              placeholder="Search Student"
                              type="text"
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                              onKeyPress={(e) => EnterHandler(e)}
                            />
                            <div className="input-group-prepend">
                              <div
                                className="search-icon"
                                onClick={searchClicked}
                              >
                                <img
                                  src={search.length > 0 ? close : Search}
                                  className="svg-icon"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mr-2">
                          <Select
                            searchable={false}
                            className="select-custom"
                            placeholder="Rooms"
                            options={[
                              { id: 0, name: "All Classes" },
                              ...classList,
                            ]}
                            onChange={(values) => {
                              changeClassRoom(values);
                              setSelectedClass(values[0]);
                            }}
                            style={{ width: "100%" }}
                            labelField="name"
                            values={[selectedClass]}
                            valueField="id"
                          />
                        </div>

                        <div className="mr-2">
                          <Select
                            searchable={false}
                            className="select-custom text-nowrap"
                            options={[
                              { id: 0, title: "All Timing" },
                              ...timingList,
                            ]}
                            onChange={(values) => {
                              changeTiming(values);
                              setSelectedTiming(values[0]);
                            }}
                            style={{ width: "100%" }}
                            labelField="title"
                            values={[selectedTiming]}
                            valueField="id"
                          />
                        </div>
                        <div className="mr-2">
                          <Select
                            searchable={false}
                            className="select-custom"
                            options={[...AttendanceList]}
                            onChange={(values) => {
                              changeStatus(values);
                              setSelectedAttendanceStatus(values[0]);
                            }}
                            style={{ width: "100%" }}
                            labelField="title"
                            values={[selectedAttendanceStatus]}
                            valueField="value"
                          />
                        </div>

                        <div className="mr-2">
                          <Select
                            searchable={false}
                            className="select-custom custom-select3"
                            placeholder="Days Per Week"
                            options={DaysList}
                            onChange={(values) => changeDaysPerWeek1(values)}
                            style={{ width: "100%" }}
                            labelField="name"
                            multi
                            values={daysPerWeek1}
                            valueField="value"
                            contentRenderer={customRenderer}
                          />
                        </div>

                        <div className="mr-2">
                          <Select
                            searchable={false}
                            className="select-custom dropdown-left"
                            placeholder="Count Per Week"
                            options={options}
                            onChange={(values) => changeDaysPerWeek(values)}
                            style={{ width: "100%" }}
                            labelField="name"
                            values={[{ id: 0, name: "Count Per Week" }]}
                            valueField="value"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="table-wrapper">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col" width="36px">
                                    <label className="fancy-checkbox element-left ">
                                      <input
                                        type="checkbox"
                                        name="allselect"
                                        onChange={handleAllSelectedStudent}
                                        checked={
                                          selectedStudentId.length > 0 &&
                                          selectedStudentId.length ==
                                            studentList.length
                                        }
                                      />
                                      <span tabIndex={0}></span>
                                    </label>
                                  </th>
                                  <th scope="col">
                                    {t("Students Name")}
                                    <img
                                      src={Sort}
                                      className="ml-1 fa-sort"
                                      alt=""
                                      onClick={() => {
                                        handleSort("student.first_name");
                                      }}
                                    />
                                  </th>
                                  <th scope="col">
                                    {t("Timing")}
                                    <img
                                      src={Sort}
                                      className="ml-1 fa-sort"
                                      alt=""
                                      onClick={() => {
                                        handleSort("timing.title");
                                      }}
                                    />
                                  </th>
                                  <th scope="col">
                                    {t("Check-in")}
                                    <img
                                      src={Sort}
                                      className="ml-1 fa-sort"
                                      alt=""
                                      onClick={() => {
                                        handleSort("attendance.checkin_time");
                                      }}
                                    />
                                  </th>
                                  <th scope="col">
                                    {t("Check-out")}
                                    <img
                                      src={Sort}
                                      className="ml-1 fa-sort"
                                      alt=""
                                      onClick={() => {
                                        handleSort("attendance.checkout_time");
                                      }}
                                    />
                                  </th>
                                  <th scope="col">
                                    {t("Attendance")}
                                    <img
                                      src={Sort}
                                      className="ml-1 fa-sort"
                                      alt=""
                                      onClick={() => {
                                        handleSort("attendance.status");
                                      }}
                                    />
                                  </th>
                                  <th scope="col">Days Per Week</th>
                                </tr>
                              </thead>
                              {studentList.length > 0 ? (
                                <>
                                  {studentList.map((stu, index) => {
                                    const dayBooleanValues = {
                                      M: stu.student_monday?.data[0],
                                      TU: stu.student_tuesday?.data[0],
                                      W: stu.student_wednesday?.data[0],
                                      TH: stu.student_thursday?.data[0],
                                      F: stu.student_friday?.data[0],
                                    };
                                    return (
                                      <tbody key={index}>
                                        <tr>
                                          <td>
                                            <label className="fancy-checkbox element-left">
                                              <input
                                                type="checkbox"
                                                name={stu.first_name}
                                                onChange={StudentChecked}
                                                value={stu.id}
                                                checked={selectedStudentId.includes(
                                                  stu.id
                                                )}
                                              />
                                              <span tabIndex={0}></span>
                                            </label>
                                          </td>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              {stu.photo ? (
                                                <img
                                                  className="rounded-circle "
                                                  style={{
                                                    width: "40px",
                                                    height: "40px",
                                                  }}
                                                  src={
                                                    imageStudentList + stu.photo
                                                  }
                                                  alt=""
                                                />
                                              ) : (
                                                <Avatar
                                                  aria-label="recipe"
                                                  className="avatar-font"
                                                >
                                                  {stu.first_name.toUpperCase()[0] +
                                                    stu.last_name.toUpperCase()[0]}
                                                </Avatar>
                                              )}
                                              <div
                                                style={{ cursor: "pointer" }}
                                              >
                                                <a
                                                  className="ml-2"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    history.push({
                                                      pathname: "/profileTabs",
                                                      studentId: stu.id,
                                                    });
                                                  }}
                                                >
                                                  {stu.first_name}{" "}
                                                  {stu.last_name}
                                                </a>
                                                <div className="ml-2 text-secondary">
                                                  {Util.studentIdFormat(stu.id)}
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            {Util.isValidData(stu.timing)
                                              ? stu.timing
                                              : "-"}
                                          </td>
                                          <td style={{ cursor: "pointer" }}>
                                            {stu.attendance_status == 0 ||
                                            stu.attendance_status === 2 ? (
                                              <>-</>
                                            ) : (
                                              <>
                                                {stu.attendance_checkin_time ? (
                                                  <a>
                                                    {moment(
                                                      stu.attendance_checkin_time
                                                    ).format(
                                                      Util.getMomentDateTimeFormat(
                                                        school.time_format
                                                      )
                                                    )}
                                                    {/* <RestartAltIcon
                                                className="ml-1"
                                                onClick={() => {
                                                  setResetModal(true);
                                                  setAttendanceId(
                                                    stu.attendance_id
                                                  );
                                                }}
                                                fontSize="small"
                                              /> */}
                                                    <img
                                                      src={restore}
                                                      height={15}
                                                      width={15}
                                                      className="ml-1"
                                                      onClick={() => {
                                                        setResetModal(true);
                                                        setAttendanceId(
                                                          stu.attendance_id
                                                        );
                                                      }}
                                                    />
                                                  </a>
                                                ) : (
                                                  <>
                                                    <a
                                                      className="link-button basic-text"
                                                      onClick={() => {
                                                        if (
                                                          stu.attendance_status ===
                                                            "0" ||
                                                          stu.attendance_status ===
                                                            0
                                                        ) {
                                                          setCheckInError(true);
                                                          setAlertModal(true);
                                                        } else {
                                                          setCheckInModal(true);
                                                          setSingleStudentId(
                                                            stu.id
                                                          );
                                                          // setSelectedStudentId([stu.id]);
                                                        }
                                                      }}
                                                    >
                                                      {t("Check-in")}
                                                    </a>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </td>
                                          <td style={{ cursor: "pointer" }}>
                                            {stu.attendance_status == 0 ||
                                            stu.attendance_status === 2 ? (
                                              <>-</>
                                            ) : (
                                              <>
                                                {stu.attendance_checkout_time ? (
                                                  <a>
                                                    {moment(
                                                      stu.attendance_checkout_time
                                                    ).format(
                                                      Util.getMomentDateTimeFormat(
                                                        school.time_format
                                                      )
                                                    )}
                                                  </a>
                                                ) : (
                                                  <a
                                                    className="link-button basic-text"
                                                    onClick={() => {
                                                      if (
                                                        stu.attendance_status ===
                                                          "0" ||
                                                        stu.attendance_status ===
                                                          0 ||
                                                        stu.attendance_status ===
                                                          null ||
                                                        stu.attendance_checkin_time ===
                                                          null
                                                      ) {
                                                        setCheckOutError(true);
                                                        setAlertModal(true);
                                                      } else {
                                                        setCheckOutModal(true);
                                                        setSingleStudentId(
                                                          stu.id
                                                        );
                                                        // setSelectedStudentId([stu.id]);
                                                      }
                                                    }}
                                                  >
                                                    {t("Check-out")}
                                                  </a>
                                                )}
                                              </>
                                            )}
                                          </td>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <TagButton
                                                size={"100px"}
                                                title={
                                                  stu.attendance_status === 0
                                                    ? `${t("Absent")}`
                                                    : stu.attendance_status ===
                                                      1
                                                    ? `${t("Present")}`
                                                    : stu.attendance_status ===
                                                      2
                                                    ? `${t("Leave")}`
                                                    : `${t("Pending")}`
                                                }
                                                buttonStyle={
                                                  stu.attendance_status === 2
                                                    ? redTagStyle
                                                    : stu.attendance_status ===
                                                      1
                                                    ? greenTagStyle
                                                    : stu.attendance_status ===
                                                      0
                                                    ? browneTagStyle
                                                    : orangeTagStyle
                                                }
                                                icon="fa fa-circle"
                                                iconSize="6px"
                                              />
                                              {stu.attendance_status == 0 && (
                                                <img
                                                  src={restore}
                                                  height={15}
                                                  width={15}
                                                  className="ml-1"
                                                  onClick={() => {
                                                    setResetModal(true);
                                                    setAttendanceId(
                                                      stu.attendance_id
                                                    );
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="circle-container">
                                              {weekdays.map((letter, index) => (
                                                <CircleLetter
                                                  key={index}
                                                  letter={letter}
                                                  isTrue={
                                                    dayBooleanValues[letter]
                                                  }
                                                />
                                              ))}
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  })}
                                </>
                              ) : (
                                <tfoot>
                                  <tr>
                                    <td colSpan={6}>
                                      {t("Student Not Found")}
                                    </td>
                                  </tr>
                                </tfoot>
                              )}
                            </table>
                          </div>

                          <div className="row m-0 table-footer border-top">
                            <div
                              className="d-flex justify-content-between align-items-center"
                              style={{ height: "72px" }}
                            >
                              <div className="d-flex align-items-center gap-2">
                                <div
                                  className="basic-text"
                                  style={{ fontWeight: "400" }}
                                >
                                  {t("Students Per Page")}
                                </div>
                                <select
                                  style={{ width: "72px", cursor: "pointer" }}
                                  className=" select-dropdown custom-input"
                                  value={recordPerPage}
                                  onChange={(e) => {
                                    setRecordPerPage(e.target.value);
                                    setCurrentPage(1);
                                  }}
                                >
                                  <option value={10}>10</option>
                                  <option value={25}>25</option>
                                  <option value={50}>50</option>
                                  <option value={100}>100</option>
                                </select>
                              </div>
                              {totalPages > 0 && (
                                <Stack
                                  spacing={2}
                                  direction="row"
                                  alignItems="center"
                                >
                                  <div
                                    className={` ${
                                      currentPage == 1
                                        ? "disabled"
                                        : "link-button"
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handlePreviousPage();
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <KeyboardArrowLeftIcon />
                                    <span>{t("Previous")}</span>
                                  </div>
                                  <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={(event, page) => {
                                      setCurrentPage(page);
                                    }}
                                    size="small"
                                    hideNextButton
                                    hidePrevButton
                                  />
                                  <div
                                    className={` ${
                                      currentPage == totalPages
                                        ? "disabled"
                                        : "link-button"
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleNextPage();
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span>{t("Next")}</span>
                                    <KeyboardArrowRightIcon />
                                  </div>
                                </Stack>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <Loader />
                )}
              </>
            ) : (
              <div className="row">
                <div className="col-md-8 offset-md-2 ">
                  <div className="alert alert-warning text-center m-2">
                    {t("No academic year at this time")}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        style={{ borderRadius: "8px" }}
        size="sm"
        onHide={() => {
          setAlertModal(false);
          setCheckInError(false);
          setCheckOutError(false);
          setAbsentError(false);
          setLeaveError(false);
        }}
        show={alertModal}
        animation={true}
        centered={true}
        className="custom-attendance-modal"
      >
        <Modal.Header className="border-0">
          <div
            className="icon-area text-right "
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              right: getLanguage !== "ar" && 0,
              left: getLanguage == "ar" && 0,
              top: 15,
            }}
          >
            <button className="hidden-btn">
              <img
                src={Close}
                alt="Close"
                className="svg-icon"
                onClick={() => {
                  setAlertModal(false);
                  setCheckInError(false);
                  setCheckOutError(false);
                  setAbsentError(false);
                  setLeaveError(false);
                }}
                height={20}
                width={20}
              />
            </button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center ">
            {checkInError ? (
              <>
                <h5>{t("check-in isn't available")}</h5>
                <div className="basic-text">
                  {t("Looks like you have selected a student marked as absent")}
                  .
                  {t(
                    "Please check-in the student to enable their respective check-out option"
                  )}
                  .
                </div>
              </>
            ) : checkOutError ? (
              <>
                <h5>{t("Check-out isn't available")}</h5>
                <div className="basic-text">
                  {t(
                    "Looks like you have selected a student yet to be checked-in"
                  )}
                  .
                  {t(
                    "Please check-in the student to enable their respective check-out option"
                  )}
                  .
                </div>
              </>
            ) : absentError ? (
              <>
                <h5>{t("Absent isn't available")}</h5>
                <div className="basic-text">
                  {t(
                    "Looks like you have selected a student marked as absent or already present"
                  )}
                  .
                  {t(
                    "Please check-in the student to enable their respective check-out option"
                  )}
                  .
                </div>
              </>
            ) : (
              leaveError && (
                <>
                  <h5>{t("Reset isn't available")}</h5>
                  <div className="basic-text">
                    {t("Looks like you have selected a student is on Leave")}
                  </div>
                </>
              )
            )}
            <p>{t("please check the student list and try again")}.</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        style={{ borderRadius: "8px" }}
        size="sm"
        onHide={() => {
          setCheckInModal(false);
          setCheckInTime(null);
        }}
        show={checkInModal}
        animation={true}
        centered={true}
        className="custom-attendance-modal"
        onShow={() => {
          setCheckInTime(new Date());
          setCheckInTimeError("");
        }}
      >
        <div className="d-flex justify-content-between align-items-center p-4 ">
          <div className="drawer-sub-header">{t("Check-in")}</div>
          <button className="hidden-btn">
            <img
              src={close}
              alt="close"
              className="svg-icon"
              height={20}
              width={20}
              onClick={() => {
                setCheckInModal(false);
                setCheckInTime(null);
              }}
            />
          </button>
        </div>

        <Modal.Body>
          <div className="input-filed text-start">
            <span className="required">{t("Check-in Time")}</span>
            <div className="time-picker">
              <DatePicker
                selected={checkInTime}
                onChange={(t) => {
                  setCheckInTime(t);
                  setCheckInTimeError("");
                }}
                dateFormat={school.time_format}
                popperPlacement="bottom"
                className={`form-control timePicker custom-input ${
                  checkInTimeError.length > 0 ? "input-error" : ""
                }`}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                placeholderText={`${t("Select check-In Time")}`}
              />
            </div>
            {checkInTimeError.length > 0 && (
              <span className="text-danger">
                <i
                  className="fa fa-exclamation-circle mr-2"
                  aria-hidden="true"
                ></i>
                {checkInTimeError}
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 d-flex justify-content-between">
          <button
            className="secondary-modal-btn btn"
            onClick={() => {
              setCheckInModal(false);
              setSelectedStudentId([]);
              setSelectedStudent([]);
            }}
          >
            {t("Cancel")}
          </button>
          <button
            className={`${
              Util.hasPermission("ATTENDANCE_MARK")
                ? "primary-lg-btn btn"
                : "no-permission"
            }`}
            onClick={handleCheckInTime}
          >
            {t("Confirm")}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        style={{ borderRadius: "8px" }}
        size="sm"
        onHide={() => {
          setCheckOutModal(false);
          setCheckOutTime(null);
        }}
        show={checkOutModal}
        animation={true}
        centered={true}
        className="custom-attendance-modal"
        onShow={() => {
          setCheckOutTime(null);
          setCheckOutTimeError("");
        }}
      >
        <div className="d-flex justify-content-between align-items-center p-4 ">
          <div className="drawer-sub-header">{t("Check-out")}</div>
          <img
            src={close}
            alt="close"
            className="svg-icon"
            height={20}
            width={20}
            onClick={() => {
              setCheckOutModal(false);
              setCheckOutTime(null);
            }}
          />
        </div>

        <Modal.Body>
          <div className="input-filed text-start">
            <span className="required">{t("Check-out Time")}</span>
            <div className="time-picker">
              <DatePicker
                selected={checkOutTime}
                onChange={(t) => {
                  setCheckOutTime(t);
                  setCheckOutTimeError("");
                }}
                dateFormat={school.time_format}
                popperPlacement="bottom"
                className={`form-control timePicker custom-input ${
                  checkOutTimeError.length > 0 ? "input-error" : ""
                }`}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                placeholderText={`${t("Select check out Time")}`}
                minTime={checkInTime && checkInTime}
                maxTime={
                  checkInTime &&
                  new Date(
                    checkInTime.getFullYear(),
                    checkInTime.getMonth(),
                    checkInTime.getDate(),
                    23,
                    59,
                    59,
                    999
                  )
                }
              />
            </div>
            {checkOutTimeError.length > 0 && (
              <span className="text-danger">
                <i
                  className="fa fa-exclamation-circle mr-2"
                  aria-hidden="true"
                ></i>
                {checkOutTimeError}
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 d-flex justify-content-between">
          <button
            style={{ width: "126px", height: "48px" }}
            className="cancel-btn btn"
            onClick={() => {
              setCheckOutModal(false);
              setSelectedStudentId([]);
              setSelectedStudent([]);
            }}
          >
            {t("Cancel")}
          </button>
          <button
            style={{ width: "126px", height: "48px" }}
            className={`${
              Util.hasPermission("ATTENDANCE_MARK")
                ? "confirm-btn btn"
                : "no-permission"
            }`}
            onClick={() => {
              handleCheckOutTime();
            }}
          >
            {t("Confirm")}
          </button>
        </Modal.Footer>
      </Modal>
      <ModalComponent
        show={absentModal}
        onHide={() => {
          setAbsentModal(false);
          setSelectedStudentId([]);
          setSelectedStudent([]);
        }}
        title={t("Absent Students")}
        subTitle={t("Are you sure want to Absent this students")}
        size="sm"
        onCancel={t("Cancel")}
        onConfirm={t("Confirm")}
        onCancelClick={() => {
          setAbsentModal(false);
          setSelectedStudentId([]);
          setSelectedStudent([]);
        }}
        onConfirmClick={handleAbsentStudent}
      />
      <ModalComponent
        show={resetModal}
        onHide={() => {
          setResetModal(false);
          setSelectedStudentId([]);
          setSelectedStudent([]);
          setAttendanceId(0);
        }}
        title={t("Reset Attendance")}
        subTitle={t("Are you sure want to Reset this Attendance")}
        size="sm"
        onCancel={t("Cancel")}
        onConfirm={t("Confirm")}
        onCancelClick={() => {
          setResetModal(false);
          setSelectedStudentId([]);
          setSelectedStudent([]);
          setAttendanceId(0);
        }}
        onConfirmClick={handleReset}
      />
      <ModalComponent
        show={resetMultipleStudentsModal}
        onHide={() => {
          setResetMultipleStudentsModal(false);
          setSelectedStudentId([]);
          setSelectedStudent([]);
        }}
        title={t("Reset Attendance")}
        subTitle={t("Are you sure want to Reset this Attendance")}
        size="sm"
        onCancel={t("Cancel")}
        onConfirm={t("Confirm")}
        onCancelClick={() => {
          setResetMultipleStudentsModal(false);
          setSelectedStudentId([]);
          setSelectedStudent([]);
        }}
        onConfirmClick={handleResetMultipleStudent}
      />
    </>
  );
};

export default ToastHOC(AttendanceLog);

import React, { useEffect, useState } from "react";
import ToastHOC from "../../HOC/ToastHOC";
import ApiCalling from "../../../network/ApiCalling";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import { Button, Modal } from "react-bootstrap";
import Close from "../../../assets/Icon/close.png";
import Invalid from "../../../assets/Icon/invalid.svg";
import ModalContent from "../../../components/common/ModalContent";
import Sort from "../../../assets/Icon/Short.svg";
import Loader from "../../../components/common/Loader";
import ModalComponent from "../ModalComponent";

const Tax = (props) => {
  const { setIsToast } = props;
  const { t } = useTranslation();
  const [showTax, setShowTax] = useState(false);
  const [tax, setTax] = useState("");
  const [tax_percentage, setTaxPercentage] = useState(0);
  const [taxError, setTaxError] = useState("");
  const [taxPercentageError, setTaxPercentageError] = useState("");
  const [editValue, setEditValue] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [taxList, setTaxList] = useState([]);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [sortColumn, setSortColumn] = useState("Tax");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    setSortColumn(columnName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedTaxList = [...taxList].sort((a, b) => {
    if (sortColumn === "Tax") {
      return sortDirection === "asc"
        ? a.tax_name.localeCompare(b.tax_name)
        : b.tax_name.localeCompare(a.tax_name);
    } else if (sortColumn === "Tax_Percentage") {
      return sortDirection === "asc"
        ? a.tax_percentage - b.tax_percentage
        : b.tax_percentage - a.tax_percentage;
    }
    return 0;
  });

  const handleAddTax = () => {
    setShowTax(true);
    setEditValue(null);
    setTax("");
    setTaxError("");
    setTaxPercentage(0);
    setTaxPercentageError("");
  };

  const handleClose = () => {
    setShowTax(false);
    setTax("");
    setTaxError("");
    setTaxPercentage(0);
    setTaxPercentageError("");
  };

  const handleSaveAndClose = () => {
    setTaxError("");
    setTaxPercentageError("");
    let error = false;
    if (tax === "") {
      setTaxError(t("Tax is required"));
      error = true;
    }
    if (tax_percentage <= 0 || tax_percentage == null) {
      setTaxPercentageError("Tax Percentage is required");
      error = true;
    }

    if (!error) {
      if (isClickedOnce) {
        return;
      }
      setIsClickedOnce(true);
      setIsLoading(true);
      if (editValue !== null) {
        const data = {
          id: editValue.id,
          tax_name: tax,
          tax_percentage: Number(tax_percentage),
        };

        ApiCalling.apiCallBodyDataPost("tax/update", data)
          .then((res) => {
            if (res.data.status === true) {
              setShowTax(false);
              setIsLoading(false);
              setIsToast(res.data.message);

              getTaxList();
            }
          })
          .catch((err) => {
            console.log("Error");
          });
      } else {
        const data = {
          tax_name: tax,
          tax_percentage: Number(tax_percentage),
        };
        ApiCalling.apiCallBodyDataPost("tax/create", data)
          .then((res) => {
            if (res.data.status === true) {
              setShowTax(false);
              setIsLoading(false);
              setIsToast(res.data.message);

              getTaxList();
            }
          })
          .catch((err) => {
            console.log("Error");
          });
      }
    }
  };

  const getTaxList = () => {
    setIsLoading(true);
    ApiCalling.apiCallParamsGet("tax/school")
      .then((res) => {
        setTaxList(res.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    ApiCalling.apiCallDelete(`tax/delete/${deleteId}`)
      .then((res) => {
        setIsLoading(false);
        setIsToast(t("Tax Deleted Successfully"));
        setShowDeleteConfirmation(false);
        getTaxList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTaxList();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="header text-right px-0 mb-2">
            <button
              className={`${
                Util.hasPermission("MASTERS_ADD")
                  ? "btn custom-primary"
                  : "no-permission"
              }`}
              type="button"
              aria-expanded="false"
              style={{ color: "#fff", padding: "5px, 10px" }}
              onClick={handleAddTax}
            >
              {t("Add Tax")}
            </button>
          </div>
        </div>

        <Modal
          show={showTax}
          onHide={handleClose}
          animation={true}
          centered={true}
          dialogClassName="modal-view"
          onShow={() => {
            setIsClickedOnce(false);
            if (editValue !== null) {
              setTax(editValue.tax_name);
              setTaxPercentage(editValue.tax_percentage);
            }
          }}
        >
          <div className="d-flex justify-content-between align-items center p-4 border-bottom">
            <h6>{editValue !== null ? t("Edit Tax ") : t("Add Tax")}</h6>

            <button className="hidden-btn" onClick={handleClose}>
              <img src={Close} className="svg-icon" height={20} width={20} />
            </button>
          </div>
          <ModalContent isLoading={isLoading}>
            <Modal.Body>
              <div className="form-group text-start">
                <label className="basic-text required">{t("Tax Name")}</label>
                <input
                  className={`form-control ${
                    taxError.length > 0 ? "input-error" : "custom-input"
                  }`}
                  placeholder={t("Enter tax tax_percentage")}
                  type="text"
                  value={tax}
                  onChange={(event) => {
                    setTax(event.target.value);
                    setTaxError("");
                  }}
                />
                {taxError.length > 0 && (
                  <span className="text-danger invalid-error">
                    <img
                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                      src={Invalid}
                    />
                    {taxError}
                  </span>
                )}
              </div>
              <div className="form-group text-start">
                <label className="basic-text required">
                  {t("Tax Percentage")}
                </label>
                <input
                  className={`form-control ${
                    taxPercentageError.length > 0
                      ? "input-error"
                      : "custom-input"
                  }`}
                  placeholder={t("Enter tax percentage")}
                  type="number"
                  value={tax_percentage}
                  onChange={(event) => {
                    setTaxPercentage(event.target.value);
                    setTaxPercentageError("");
                  }}
                />
                {taxPercentageError.length > 0 && (
                  <span className="text-danger invalid-error">
                    <img
                      className="fa fa-exclamation-circle mr-2 invalid-svg"
                      src={Invalid}
                    />
                    {taxPercentageError}
                  </span>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn custom-primary"
                onClick={handleSaveAndClose}
              >
                {t("Save")}
              </Button>
            </Modal.Footer>
          </ModalContent>
        </Modal>

        <div className="row clearfix ">
          <div className="table-div overflow-hidden mt-2 p-0">
            <div className="col-lg-12 col-md-12 p-0 m-0">
              <div className="body">
                <>
                  {!isLoading ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">
                            {t("Tax Name")}{" "}
                            <img
                              src={Sort}
                              className="ml-1 fa-sort"
                              alt=""
                              onClick={() => {
                                handleSort("Tax");
                              }}
                            />
                          </th>
                          <th scope="col">
                            Tax Percentage{" "}
                            <img
                              src={Sort}
                              className="ml-1 fa-sort"
                              alt=""
                              onClick={() => {
                                handleSort("Tax_Percentage");
                              }}
                            />
                          </th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      {taxList.length > 0 ? (
                        <tbody>
                          {sortedTaxList.map((t, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{t.tax_name}</td>
                                <td>{t.tax_percentage}</td>
                                <td>
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setEditValue(t);
                                      setShowTax(true);
                                    }}
                                    className={`${
                                      !Util.hasPermission("MASTERS_EDIT") &&
                                      "no-permission"
                                    }`}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </a>
                                </td>
                                <td>
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowDeleteConfirmation(true);
                                      setDeleteId(t.id);
                                    }}
                                    className={`${
                                      Util.hasPermission("MASTERS_DELETE")
                                        ? "pl-5"
                                        : "no-permission"
                                    }`}
                                  >
                                    <i className="fa fa-trash-o text-danger"></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      ) : (
                        <tfoot>
                          <tr>
                            <td colSpan={4}>
                              {t(
                                "There are no Tax. Click the 'Add Tax ' button to create one."
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      )}
                    </table>
                  ) : (
                    <Loader />
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        show={showDeleteConfirmation}
        onHide={() => {
          setShowDeleteConfirmation(false);
        }}
        title={t("Delete")}
        onCancel={t("Cancel")}
        onConfirm={t("Confirm")}
        subTitle={t("Are you sure, to delete this Tax?")}
        size="sm"
        onCancelClick={() => {
          setShowDeleteConfirmation(false);
        }}
        onConfirmClick={handleDelete}
      />
    </div>
  );
};

export default ToastHOC(Tax);

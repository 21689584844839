import React, { useEffect, useRef, useState } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import Util from "../../../Util";
import { useTranslation } from "react-i18next";
import ApiCalling from "../../../network/ApiCalling";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import moment from "moment/moment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Calander from "../../../assets/Icon/calendar.svg";
import ExitAlert from "../../../components/ExitAlert";
import Invalid from "../../../assets/Icon/invalid.svg";
import Loader from "../../../components/common/Loader";
import Close from "../../../assets/Icon/close.png";

export default function AddPayment({
  show,
  onClose,
  onSave,
  studentIds,
  studentName,
  PaymentDetails,
  invoiceDetails,
  updateData,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const school = Util.getSelectedSchool();
  const getLanguage = Util.getSelectedLanguage();
  const datepickerRef = useRef(null);
  const [parentList, setParentList] = useState([]);
  const [parentId, setParentId] = useState(0);
  const [parentIdError, setParentIdError] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [paymentDateError, setPaymentDateError] = useState("");
  const [note, setNote] = useState("");
  const [isStateUpdated, setIsStateUpdated] = useState(false);
  const [alertModal, setIsAlertModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const PaymentMethodList = [
    { value: "Cash", name: "Cash" },
    { value: "Bank Channel", name: "Bank Channel" },
    { value: "Online", name:"Online"},
    { value: "POS", name:"POS"},
  ];

  const CustomInput = React.forwardRef(({ value, onClick, icon }, ref) => (
    <div className="d-flex" onClick={onClick}>
      <input
        ref={ref}
        className={`custom-input__field ${
          paymentDateError.length > 0 && "datePicker-error"
        }`}
        value={value}
        readOnly
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon  calender-icon" />
      </div>
    </div>
  ));

  const getParentList = async () => {
    ApiCalling.apiCallParamsGet("parent/" + studentIds).then((res) => {
      setParentList(res.data);
    });
  };
  useEffect(() => {
    if (studentIds) {
      getParentList();
    }
  }, [studentIds]);
  const handleParentId = (e) => {
    setParentId(e.target.value);
    setParentIdError("");
    setIsStateUpdated(true);
  };
  const handlePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
    setPaymentMethodError("");
    setIsStateUpdated(true);
  };
  const toggleDatePicker = () => {
    datepickerRef.current.setOpen(true);
  };
  const handleAmount = (e) => {
    setAmount(e.target.value);
    setAmountError("");
    setIsStateUpdated(true);
  };
  const handlePaymentDate = (date) => {
    setPaymentDate(date);
    setPaymentDateError("");
    setIsStateUpdated(true);
  };
  const handleNote = (e) => {
    setNote(e.target.value);
    setIsStateUpdated(true);
  };
  const reset = () => {
    setParentId(0);
    setPaymentMethod("");
    setAmount("");
    setNote("");
    setParentIdError("");
    setPaymentMethodError("");
    setAmountError("");
    setPaymentDateError("");
  };
  const generatePayment = (e) => {
    setIsStateUpdated(false);
    e.preventDefault();
    setParentIdError("");
    setPaymentMethodError("");
    setAmountError("");
    setPaymentDateError("");
    let error = false;
    if (parentId == 0) {
      setParentIdError(t("Please select parent who paid"));
      error = true;
    }
    if (paymentMethod == "") {
      setPaymentMethodError(t("Please select payment method"));
      error = true;
    }
    if (amount == 0) {
      setAmountError(t("Please insert amount paid"));
      error = true;
    }
    if (paymentDate == null) {
      setPaymentDateError(t("Please choose date paid"));
      error = true;
    }
    if (!error) {
      setIsLoading(true);
      const data = {
        student_id: studentIds,
        user_id: parentId,
        amount: amount,
        payment_method: paymentMethod,
        note: note,
        invoice_id: invoiceDetails ? invoiceDetails.id : "",
        date: paymentDate,
      };
      ApiCalling.apiCallBodyDataPost("payment/add-manual", data)
        .then((res) => {
          if (res.data.status == true) {
            setIsLoading(false);
            onSave(res.data.message);
            updateData();
            reset();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  useEffect(() => {
    if (Util.isValidData(PaymentDetails)) {
      setParentId(PaymentDetails.user_id);
      setPaymentMethod(PaymentDetails.payment_method);
      setAmount(PaymentDetails.amount);
      setNote(PaymentDetails.note ? PaymentDetails.note : "");
      setPaymentDate(new Date(PaymentDetails.date));
    } else if (Util.isValidData(invoiceDetails)) {
      setAmount(invoiceDetails?.due);
      setParentId(0);
      setPaymentMethod("");
      setNote("");
    }
  }, [PaymentDetails, invoiceDetails, show]);

  return (
    <div>
      <SwipeableDrawer
        // hideBackdrop={true}
        anchor={getLanguage == "ar" ? "left" : "right"}
        open={show}
        onClose={() => {
          if (isStateUpdated) {
            setIsAlertModal(true);
          } else {
            setIsStateUpdated(false);
            onClose();
            if (
              !Util.isValidData(PaymentDetails) ||
              !Util.isValidData(invoiceDetails)
            ) {
              reset();
            }
          }
        }}
        onOpen={() => {}}
      >
        <div
          className="box-area"
          // style={{ position: "relative", width: "100%", height: "100%" }}
        >
          <Box sx={{ width: "656px", padding: "20px", height:"120%" }} role="presentation">
            <div className="d-flex align-items-center justify-content-between">
              <div className="drawer-header">
                {t("Log a payment for")} "{studentName}"
              </div>
              {/* <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  if (isStateUpdated) {
                    setIsAlertModal(true);
                  } else {
                    setIsStateUpdated(false);
                    onClose();
                    if (
                      !Util.isValidData(PaymentDetails) ||
                      !Util.isValidData(invoiceDetails)
                    ) {
                      reset();
                    }
                  }
                }}
              >
                <CloseIcon />
              </IconButton> */}
               <button
                className="hidden-btn"
                onClick={() => {
                  if (isStateUpdated) {
                    setIsAlertModal(true);
                  } else {
                    setIsStateUpdated(false);
                    onClose();
                    if (
                      !Util.isValidData(PaymentDetails) ||
                      !Util.isValidData(invoiceDetails)
                    ) {
                      reset();
                    }
                  }
                }}
              >
                <img src={Close} className="svg-icon" height={20} width={20} />
              </button>
            </div>
            {!isLoading ? (
              <div className="body mt-3">
                <div className="row text-start mt-2">
                  <div className="input-filed pb-2">
                    <span className="basic-text required">{t("Paid by")}</span>
                    {parentList.length > 0 ? (
                      <>
                        <select
                          className={`select-dropdown form-select ${
                            parentIdError.length > 0
                              ? "input-error"
                              : "custom-input"
                          }`}
                          onChange={handleParentId}
                          value={parentId}
                          disabled={Util.isValidData(PaymentDetails)}
                        >
                          <option selected disabled value={0}>
                            {t("Select")}
                          </option>
                          {parentList.map((item) => {
                            return (
                              <option key={item.id} value={item.User.id}>
                                {item.User.firstName + " " + item.User.lastName}
                              </option>
                            );
                          })}
                        </select>
                        {parentIdError.length > 0 && (
                          <span className="text-danger invalid-error">
                            <img
                              className="fa fa-exclamation-circle mr-2 invalid-svg"
                              src={Invalid}
                            />
                            {parentIdError}
                          </span>
                        )}
                      </>
                    ) : (
                      <div className="negative">
                        {t("Not added any Payer!!! Please add first")}
                      </div>
                    )}
                  </div>

                  <div className="input-filed ">
                    <span className="basic-text required">
                      {t("Payment Method")}
                    </span>
                    <select
                      className={`select-dropdown form-select ${
                        paymentMethodError.length > 0
                          ? "input-error"
                          : "custom-input"
                      }`}
                      // placeholder="First Name"
                      onChange={handlePaymentMethod}
                      value={paymentMethod}
                      disabled={Util.isValidData(PaymentDetails)}
                    >
                      <option value="">{t("Select")}</option>
                      {PaymentMethodList?.map((item) => (
                        <option value={item.value} key={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {paymentMethodError.length > 0 && (
                      <span className="text-danger invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {paymentMethodError}
                      </span>
                    )}
                  </div>

                  <div className="input-filed ">
                    <span className="basic-text required">
                      {t("Amount paid")}
                    </span>
                    <input
                      type="number"
                      className={`form-control ${
                        amountError.length > 0 ? "input-error" : "custom-input"
                      }`}
                      onChange={handleAmount}
                      value={amount}
                      // disabled={
                      //   Util.isValidData(PaymentDetails) ||
                      //   Util.isValidData(invoiceDetails)
                      // }
                    />
                    {amountError.length > 0 && (
                      <span className="text-danger invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {amountError}
                      </span>
                    )}
                  </div>

                  <div className="input-filed ">
                    <span className="basic-text required">
                      {t("Date paid")}
                    </span>

                    <DatePicker
                      ref={datepickerRef}
                      selected={paymentDate}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onChange={handlePaymentDate}
                      dateFormat={school.date_format}
                      popperPlacement="bottom"
                      disabled={Util.isValidData(PaymentDetails)}
                      customInput={<CustomInput icon={Calander} />}
                    />

                    {paymentDateError.length > 0 && (
                      <span className="text-danger invalid-error">
                        <img
                          className="fa fa-exclamation-circle mr-2 invalid-svg"
                          src={Invalid}
                        />
                        {paymentDateError}
                      </span>
                    )}
                  </div>

                  <div className="input-filed ">
                    <span className="basic-text" style={{ fontWeight: "400" }}>
                      {t("Notes")}
                    </span>
                    <textarea
                      rows={3}
                      className=" text-area auto-height"
                      cols={5}
                      value={note}
                      onChange={handleNote}
                      disabled={Util.isValidData(PaymentDetails)}
                    />
                  </div>
                  {!Util.isValidData(PaymentDetails) && (
                    <div
                      className="border-top"
                      style={{
                        position: "fixed",
                        width: "656px",
                        bottom: "0px",
                        right: getLanguage !== "ar" && "0px",
                        background: "#ffffff",
                        padding: "10px",
                      }}
                    >
                      <button
                        className="secondary-md-btn btn mr-2"
                        onClick={() => {
                          if (isStateUpdated) {
                            setIsAlertModal(true);
                          } else {
                            onClose();
                            reset();
                            setIsStateUpdated(false);
                          }
                        }}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        style={{ float: "right" }}
                        className="primary-lg-btn btn"
                        onClick={generatePayment}
                        disabled={parentList.length === 0}
                      >
                        {t("Save")}
                      </button>
                    </div>
                  )}
                </div>
                {Util.isValidData(invoiceDetails) && (
                  <div className="box-area">
                    <span className="basic-text">
                      {t("Apply Payment against Invoices ")}
                    </span>
                    <table
                      className="table"
                      style={{
                        border: "1px solid #e5e7eb",
                        borderRadius: "12px",
                      }}
                    >
                      <thead style={{ backgroundColor: "#e5e7eb" }}>
                        <tr>
                          <th>{t("Invoice")}</th>
                          <th>{t("Fee Type")}</th>
                          <th>{t("Date")}</th>
                          <th>{t("Amount")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{Util.invoiceIdFormat(invoiceDetails.id)}</td>
                          <td>
                            {invoiceDetails.types.length > 0
                              ? invoiceDetails.types
                                  .map((f) => f.Fee_Type)
                                  .join(", ")
                              : "-"}
                          </td>
                          <td>
                            {moment(invoiceDetails.date).format(
                              Util.getMomentDateTimeFormat(school.date_format)
                            )}
                          </td>
                          <td>{Util.balanceFormat(invoiceDetails.due)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                {Util.isValidData(PaymentDetails) && (
                  <div className="box-area">
                    <span className="basic-text">
                      {t("Apply Payment against Invoices ")}
                    </span>
                    <table
                      className="table"
                      style={{
                        border: "1px solid #e5e7eb",
                        borderRadius: "12px",
                      }}
                    >
                      <thead style={{ backgroundColor: "#e5e7eb" }}>
                        <tr>
                          <th>{t("Invoice")}</th>
                          <th>{t("Fee Type")}</th>
                          <th>{t("Date")}</th>
                          <th>{t("Amount")}</th>
                          <th>{t("Amount Adjusted")}</th>
                          <th>{t("Balance")}</th>
                        </tr>
                      </thead>
                      {PaymentDetails.invoice_settled.map((In) => {
                        const type = In.types.map((t) => t.Fee_Type).join(",");
                        return (
                          <tbody key={In.invoice_id}>
                            <tr>
                              <td>{Util.invoiceIdFormat(In.invoice_id)}</td>
                              <td>{type}</td>
                              <td>
                                {moment(In.date).format(
                                  Util.getMomentDateTimeFormat(
                                    school.date_format
                                  )
                                )}
                              </td>
                              <td>{In.total}</td>
                              <td>{In.amount_adjusted}</td>
                              <td>{In.due}</td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                )}
              </div>
            ) : (
              <Loader />
            )}
          </Box>
        </div>
      </SwipeableDrawer>
      <ExitAlert
        visible={alertModal}
        onClose={() => {
          setIsAlertModal(false);
          setIsStateUpdated(false);
        }}
        onOk={() => {
          onClose();
          setIsAlertModal(false);
          reset();
          setIsStateUpdated(false);
        }}
      />
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ApiCalling from "../../network/ApiCalling";
import Util from "../../Util";
import moment from "moment";
import { imageMyProfile } from "../CommonImage";
import DotLoader from "../../components/common/DotLoader";
import Loader from "../../components/common/Loader";
import caughtup from "../../assets/Icon/caughtup.svg";
import unreadDot from "../../assets/Icon/unread-dot.svg";
import { useSelector } from "react-redux";

const NotificationModal = ({ size, show, onHide }) => {
  const ParentMode = Util.getParentMode();
  const selectedStudent = useSelector((state) => state.SelectedStudentReducer);
  const { t } = useTranslation();
  const user = Util.getUser();
  const [notificationList, setNotificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [endOfList, setEndOfList] = useState(false);
  const [notificationId, setNotificationId] = useState("");
  const [isNotTodaysNotification, setIsNotTodaysNotification] = useState(false);
  const [isNotOldNotification, setIsNotOldNotification] = useState(false);

  const modalRef = useRef(null);

  const now = moment();
  const twentyFourHoursAgo = moment().subtract(24, "hours");

  const last24HoursData = notificationList.filter((item) =>
    moment(item.sent_on).isBetween(twentyFourHoursAgo, now)
  );

  const olderData = notificationList.filter(
    (item) => !moment(item.sent_on).isBetween(twentyFourHoursAgo, now)
  );

  const getNotificationList = () => {
    setIsLoading(true);
    const data = {
      page_number: currentPage,
      page_size: recordPerPage,
    };
    ApiCalling.apiCallBodyDataPost(`notification/list/user/${user.id}`, data)
      .then((res) => {
        console.log("notification", res.data);
        setIsLoading(false);
        setTotalPages(res.data.totalPages);
        if (res.data.data.length > 0) {
          setNotificationList((prevData) => [...prevData, ...res.data.data]);
          const isToday = res.data.data.filter((item) =>
            moment(item.sent_on).isBetween(twentyFourHoursAgo, now)
          ).length;
          const isOld = res.data.data.filter(
            (item) => !moment(item.sent_on).isBetween(twentyFourHoursAgo, now)
          ).length;
          if (isToday > 0) {
            setIsNotTodaysNotification(false);
          } else {
            setIsNotTodaysNotification(true);
          }
          if (isOld > 0) {
            setIsNotOldNotification(false);
          } else {
            setIsNotOldNotification(true);
          }
          const readMsg = res.data.data
            .filter((notification) => notification.read_on === null)
            .map((notification) => notification.id)
            .join(",");

          setNotificationId(readMsg);

          if (res.data.totalPages === res.data.page_number) {
            setEndOfList(true);
          }
        } else {
          setEndOfList(false);
          setIsNotTodaysNotification(true);
          setIsNotOldNotification(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getNotificationList();
  }, [currentPage, recordPerPage]);

  const readNotification = () => {
    const data = {
      notification_ids: notificationId,
    };
    ApiCalling.apiCallBodyDataPost(
      "notification-read/read",
      data,
      ParentMode && selectedStudent?.selectedStudent?.student?.School?.id
    )
      .then((res) => {
        // console.log("Read Notification", res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    const modalContent = modalRef.current;
    const handleModalScroll = () => {
      if (modalContent) {
        const scrollPosition = modalContent.scrollTop;
        const modalHeight = modalContent.clientHeight;
        const contentHeight = modalContent.scrollHeight;
        const offset = 1;

        if (
          scrollPosition + modalHeight + offset >= contentHeight &&
          !isLoading &&
          !endOfList
        ) {
          setCurrentPage((prev) => prev + 1);
        }
      }
    };

    if (modalContent) {
      modalContent.addEventListener("scroll", handleModalScroll);

      return () => {
        modalContent.removeEventListener("scroll", handleModalScroll);
      };
    }
  }, [isLoading, endOfList]);
  useEffect(() => {
    if (notificationId.length > 0) {
      if (ParentMode) {
        Util.isValidData(selectedStudent) && readNotification();
      }
    }
  }, [notificationId, ParentMode, selectedStudent]);
  useEffect(() => {
    if (notificationId) {
      if (!ParentMode) {
        readNotification();
      }
    }
  }, [notificationId, ParentMode]);
  return (
    <div className="modal-view">
      <Modal
        id="modal-bx"
        style={{
          border: "0px",
          display: "block",
          margin: "0 auto",
          borderRadius: "8px",
        }}
        // size={size}
        size="lg"
        onHide={() => {
          onHide();
        }}
        show={show}
        animation={true}
        centered={false}
        dialogClassName="modal-view"
        onShow={() => {}}
      >
        <Modal.Body>
          <div className="p-3">
            <div
              className="col-lg-12"
              ref={modalRef}
              style={{ overflowY: "auto", maxHeight: "500px" }}
            >
              <div className="text-start basic-bold-text">{t("Last 24 hours")}</div>
              {isNotTodaysNotification ? (
                <>
                  <div className="d-flex align-items-center m-4  gap-3">
                    <img src={caughtup} height={40} width={40} />
                    <div className="basic-bold-text">
                      {t("You’re all caught up")}
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  {last24HoursData?.map((n, index) => {
                    return (
                      <>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex m-3  gap-3" key={index}>
                            <div>
                              <img
                                src={
                                  n.Sent_By.avatar
                                    ? imageMyProfile + n.Sent_By.avatar
                                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                }
                                className="rounded-circle user-photo"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                }}
                                alt=""
                              />
                            </div> 
                            <div>
                              <div className="basic-bold-text">{n.title}</div>
                              <div className="basic-text" dangerouslySetInnerHTML={{__html: n.description}}></div>
                              <span className="notification-font-secondary">
                                {moment(n.sent_on).format("MMM D") +
                                  ", " +
                                  moment(n.sent_on).format("h:mm A")}
                              </span>
                            </div>
                          </div>
                          {n.read_on == null && (
                            <div>
                              <img
                                src={unreadDot}
                                className="rounded-circle user-photo"
                                style={{
                                  width: "8px",
                                  height: "8px",
                                }}
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                        <hr className="notification-hr-line m-0" />
                      </>
                    );
                  })}
                </div>
              )}

              <div className="text-start basic-bold-text mt-3">{t("Older")}</div>
              {isNotOldNotification ? (
                <>
                  <div className="d-flex align-items-center m-4  gap-3">
                    <img src={caughtup} height={40} width={40} />
                    <div className="basic-bold-text">
                      {t("You’re all caught up")}
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  {olderData?.map((n, index) => {
                    return (
                      <>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex m-3  gap-3" key={index}>
                            <div>
                              <img
                                src={
                                  n.Sent_By.avatar
                                    ? imageMyProfile + n.Sent_By.avatar
                                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                }
                                className="rounded-circle user-photo"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                }}
                                alt=""
                              />
                            </div>
                            <div>
                              <div className="basic-bold-text">{n.title}</div>
                              <div className="basic-text" dangerouslySetInnerHTML={{__html: n.description}}></div>
                              <span className="notification-font-secondary">
                                {moment(n.sent_on).format("MMM D") +
                                  ", " +
                                  moment(n.sent_on).format("h:mm A")}
                              </span>
                            </div>
                          </div>
                          {n.read_on == null && (
                            <div>
                              <img
                                src={unreadDot}
                                className="rounded-circle user-photo"
                                style={{
                                  width: "8px",
                                  height: "8px",
                                }}
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                        <hr className="notification-hr-line m-0" />
                      </>
                    );
                  })}
                </div>
              )}
              {isLoading && (
                <>
                  <Loader />
                </>
              )}
              {endOfList && (
                <div className="extra-small-text align-center text-muted pb-2">
                  ----{t("End")}----
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default NotificationModal;

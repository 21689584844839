import React, { useRef } from "react";
import { useEffect, useState } from "react";
import ApiCalling from "../../../network/ApiCalling";
import ToastHOC from "../../HOC/ToastHOC";
import Util from "../../../Util";
import { Rings } from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import CardPost from "../../../components/ActivityCard/Card-post";
import CardAnnouncement from "../../../components/ActivityCard/Card-Announcement";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import ActivityCell from "../../Activity/ActivityCell";
import Select from "react-dropdown-select";
import Calender from "../../../assets/Icon/calendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CardEvent from "../../../components/ActivityCard/CardEvent";
import moment from "moment";
import Loader from "../../../components/common/Loader";
import { useSelector } from "react-redux";
import Close from "../../../assets/Icon/close.png";

const ParentsDashboard = (props) => {
  const selected = useSelector((state) => state.SelectedStudentReducer);

  const prevSelected = useRef(selected);

  const { t } = useTranslation();
  const history = useHistory();
  const user = Util.getUser();

  const [isLoading, setIsLoading] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [endOfList, setEndOfList] = useState(false);
  const [showChildModal, setShowChildModal] = useState(false);
  const [characterCode, setCharacterCode] = useState("");
  const [characterCodeError, setCharacterCodeError] = useState("");
  const [relationListOfParent, setRelationListOfParent] = useState([]);
  const [relation, setRelation] = useState(0);
  const [relationError, setRelationError] = useState("");
  const [parentData, setParentData] = useState([]);
  const [date, setDate] = useState(null);
  const [activityCategory, setActivityCategory] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page_number, setPage_number] = useState(0);
  const [isNoData, setIsNoData] = useState(false);

  const filteredActivityList = {};

  for (const key in activityList) {
    const filteredActivities = activityList[key].filter(
      (item) => item.activity.type !== "ACTIVITY"
    );

    if (filteredActivities.length > 0) {
      filteredActivityList[key] = filteredActivities;
    }
  }

  const recordPerPage = 20;

  const activityCategoryList = [
    { value: "EVENT", title: "Event" },
    { value: "POST", title: "Post" },
    { value: "ANNOUNCEMENT", title: "Announcement" },
  ];

  const CustomInput = React.forwardRef(({ value, onClick, icon }, ref) => (
    <div className=" d-flex" onClick={onClick}>
      <input
        ref={ref}
        className="custom-input__field"
        value={value}
        readOnly
        placeholder="Select Date"
      />
      <div className="custom-input__icon">
        <img src={icon} alt="Calander" className="svg-icon calender-icon" />
      </div>
    </div>
  ));

  const changeDate = (dt) => {
    setCurrentPage(1);
    setEndOfList(false);
    setActivityList([]);
    setDate(dt);
  };

  const handleShowModal = () => {
    setShowChildModal(true);
  };
  const handleCloseModal = () => {
    setShowChildModal(false);
  };
  const handleCharacterCodeChange = (e) => {
    setCharacterCode(e.target.value);
    setCharacterCodeError("");
  };
  const handleRelation = (e) => {
    setRelation(e.target.value);
    setRelationError("");
  };
  const handleSave = (e) => {
    e.preventDefault();
    setCharacterCodeError("");
    setRelationError("");
    let error = false;
    if (characterCode === "") {
      setCharacterCodeError(t("Please enter the code"));
      error = true;
    }
    if (relation === 0) {
      setRelationError(t("Please Select Relation"));
      error = true;
    }
    if (!error) {
      const data = {
        student_invitee: characterCode,
        student_parent_relation_type_id: relation,
      };
      ApiCalling.apiCallBodyDataPost("parent/add-parent-invitee", data)
        .then((res) => {
          if (res.data.status == true) {
            ApiCalling.apiCallBodyDataGet(`user/get-user-byId/${user.id}`)
              .then((res) => {
                setParentData(res.data.Parents);
                history.push("/myChild");
              })
              .catch((error) => {
                console.log(error);
              });

            setShowChildModal(false);
            setCharacterCode("");
            setRelation(0);
            setCharacterCodeError("");
            setRelationError("");
          } else {
            if (res.data.message === "Invitee is incorrect") {
              setCharacterCodeError(res.data.message);
            } else {
              setRelationError(res.data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const relationList = async () => {
    ApiCalling.apiCallParamsGet("student-parent-relation-type").then((res) => {
      setRelationListOfParent(res.data);
    });
  };
  const parentDashBoard = () => {
    setParentData(user.Parents);
  };
  const changeCheck = (data) => {
    setCurrentPage(1);
    setActivityList([]);
    setActivityCategory(data[0].value);
  };
  const groupActivitiesByDate = (activityList) => {
    const groupedActivities = {};

    activityList.sort((a, b) => {
      const momentA = moment(a.created_on, "DD/MM/YYYY");
      const momentB = moment(b.created_on, "DD/MM/YYYY");

      return momentB - momentA;
    });

    activityList.forEach((activityData) => {
      const activity = activityData.activity;

      if (activity && activity.created_on) {
        // const createdDate = new Date(activity.created_on);
        const createdDate = moment(activity.created_on).format("MM/DD/YYYY");

        if (!groupedActivities[createdDate]) {
          groupedActivities[createdDate] = [];
        }

        groupedActivities[createdDate].push(activityData);
      }
    });

    return groupedActivities;
  };

  const ActivityList = async () => {
    setIsLoading(true);
    const data = {
      page_size: recordPerPage,
      page_number: currentPage,
      type: activityCategory,
      startDate: date !== null ? new Date(date) : null,
    };
    ApiCalling.apiCallBodyDataPost(
      `activity/getAllByStudentId/${selected?.selectedStudent?.id}`,
      data
    )
      .then((res) => {
        // setActivityList((prevList) => [...prevList, ...res.data.data]);
        // setActivityList((prevList) =>
        //   groupActivitiesByDate([...prevList, ...res.data.data])
        // );
        // setActivityList(groupActivitiesByDate(res.data.data));
        if (res.data.data.length > 0) {
          setActivityList((prevActivityList) => ({
            ...prevActivityList,
            ...groupActivitiesByDate(res.data.data),
          }));
          setTotalPages(res.data.totalPages);
          setPage_number(res.data.page_number);
          if (res.data.totalPages === res.data.page_number) {
            setEndOfList(true);
          }
          setIsLoading(false);
          setIsNoData(false);
        } else {
          setIsNoData(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleLike = (id) => {
    ApiCalling.apiCallBodyDataPost(`activity/like/${id}`)
      .then((res) => {
        let f = [];
        for (const date in activityList) {
          const updatedActivities = activityList[date].map((data) => {
            if (data.activity.id === id) {
              data.likedByMe = true;
              data.likes = data.likes + 1;
            }
            return data;
          });
          f[date] = updatedActivities;
        }
        setActivityList(f);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDisLike = (id) => {
    ApiCalling.apiCallDelete(`activity/Unlike/${id}`)
      .then((res) => {
        const updatedActivityList = { ...activityList };

        for (const date in updatedActivityList) {
          updatedActivityList[date] = updatedActivityList[date].map((data) => {
            if (data.activity.id === id) {
              data.likedByMe = false;
              data.likes = data.likes - 1;
            }
            return data;
          });
        }
        setActivityList(updatedActivityList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (totalPages == currentPage) {
      setEndOfList(true);
      setIsNoData(false);
    }
  }, [currentPage]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;

      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      const windowBottom = windowHeight + window.pageYOffset;

      if (windowBottom >= docHeight - 100 && !isLoading && !endOfList) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading, endOfList]);
  useEffect(() => {
    relationList();
    parentDashBoard();
  }, []);
  useEffect(() => {
    if (
      Util.isValidData(selected) &&
      selected?.selectedStudent?.id !== undefined
    ) {
      // console.log("student ID",selected?.selectedStudent?.id)
      ActivityList();
    }
  }, [selected, activityCategory, currentPage, date]);

  useEffect(() => {
    if (Util.isValidData(selected) && selected !== prevSelected.current) {
      console.log("selected");
      prevSelected.current = selected;
      setCurrentPage(1);
      setActivityList([]);
    }
  }, [selected]);

  return (
    <>
      <div
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div className="container-fluid">
          <div className="d-wrapper">
            <div className="row">
              {user.Parents.length == 0 && (
                <>
                  <div className="col-lg-12 offset-lg-12 text-center">
                    {/* <h5>
                      {" "}
                      {t("Welcome")} {user.firstName}....{" "}
                    </h5>
                    <p> {t("Get started by adding your child")}. </p>

                    <button
                      className="btn primary-lg-btn"
                      to="addChild"
                      onClick={handleShowModal}
                    >
                      {t("Add a Child")}
                    </button> */}
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%) translateY(-50%)",
                        bottom: 0,
                        top: "200px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        It looks like your email address isn't linked to any
                        school on Classadia. Please verify that you’ve signed up
                        using the email address your school used to invite you.
                        If you're unsure, contact your school and ask them to
                        send you an invitation.
                      </span>
                    </div>
                  </div>
                </>
              )}

              <Modal
                size="md"
                animation={true}
                centered={true}
                dialogClassName="modal-view"
                show={showChildModal}
                onHide={handleCloseModal}
                onShow={() => {
                  setCharacterCode("");
                  setRelation(0);
                  setCharacterCodeError("");
                  setRelationError("");
                }}
              >
                <div className="d-flex justify-content-between align-items center p-4 border-bottom">
                  <h6>{t("Add Child")}</h6>

                  <button className="hidden-btn" onClick={handleCloseModal}>
                    <img
                      src={Close}
                      className="svg-icon"
                      height={20}
                      width={20}
                    />
                  </button>
                </div>
                <Modal.Body>
                  <div>
                    <label>
                      {t(
                        "Please enter the 10 character Code sent by your school"
                      )}
                      .
                    </label>
                    <input
                      className="form-control"
                      placeholder={t("enter the code")}
                      value={characterCode}
                      onChange={handleCharacterCodeChange}
                    />
                    {characterCodeError.length > 0 && (
                      <span className="text-danger">{characterCodeError}</span>
                    )}
                  </div>
                  <div className="input-filed mt-2">
                    <label>{"Relation with Student"}</label>
                    <select
                      name=""
                      id=""
                      className="select-dropdown"
                      value={relation}
                      onChange={handleRelation}
                    >
                      <option selected disabled value="0">
                        {t("Select")}
                      </option>
                      {relationListOfParent?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </select>
                    {relationError ? (
                      <span className="text-danger">{relationError}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn custom-primary" onClick={handleSave}>
                    {t("Done")}
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
            {user.Parents.length > 0 && (
              <div className="activity-container">
                <div className=" d-flex justify-content-between align-items-center mb-2">
                  <div className="d-flex gap-2 pt-4 pl-0">
                    <div className="datePickerContainer position-relative">
                      <DatePicker
                        placeholderText="Select Date"
                        selected={date}
                        onChange={changeDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="datePicker"
                        popperPlacement="bottom"
                        customInput={<CustomInput icon={Calender} />}
                        maxDate={new Date()}
                      />
                    </div>
                    <div>
                      <Select
                        className="select-custom "
                        options={[
                          { value: 0, title: "Category" },
                          ...activityCategoryList,
                        ]}
                        onChange={(values) => changeCheck(values)}
                        labelField="title"
                        values={[{ value: 0, title: "Category" }]}
                        valueField="value"
                      />
                    </div>
                  </div>
                </div>
                {isNoData ? (
                  <div className="row">
                    <div
                      className="col-lg-12"
                      style={{ paddingTop: "20%", paddingBottom: "20%" }}
                    >
                      <p className="text-center text-secondary">
                        {t("No NewsFeed found")} :-(
                      </p>
                    </div>
                  </div>
                ) : (
                  <div>
                    {filteredActivityList &&
                      Object.keys(filteredActivityList).map((dt, index) => (
                        <div key={index}>
                          <div
                            className="d-flex align-items-center mt-4 mb-3"
                            key={index}
                          >
                            <div className="calender-picker">
                              <img
                                src={Calender}
                                alt=""
                                style={{ cursor: "pointer" }}
                                width={20}
                                height={20}
                              />
                            </div>
                            <div className="large-bold-text">
                              {moment(dt).format("MMMM DD, YYYY")}
                            </div>
                          </div>
                          {filteredActivityList[dt].map((data, index) => {
                            return (
                              <div key={index}>
                                {data.activity.type == "POST" ? (
                                  <CardPost
                                    activity={data}
                                    likeOnClick={() => {
                                      handleLike(data.activity.id);
                                    }}
                                    dislikeOnClick={() => {
                                      handleDisLike(data.activity.id);
                                    }}
                                    activityDetails={() => {
                                      history.push({
                                        pathname: "/Dashboard-Activity",
                                        activityId: data.activity.id,
                                        isParent: true,
                                      });
                                    }}
                                    activityDetailsPage={() => {
                                      history.push({
                                        pathname: "/Dashboard-Activity",
                                        activityId: data.activity.id,
                                        isParent: true,
                                      });
                                    }}
                                  />
                                ) : (
                                  <>
                                    {data.activity.type == "EVENT" && (
                                      <CardEvent activity={data.activity} />
                                    )}
                                  </>
                                )}
                                {data.activity.type == "ANNOUNCEMENT" && (
                                  <CardAnnouncement activity={data.activity} />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ))}

                    {isLoading && <Loader />}
                    {endOfList && (
                      <div className="extra-small-text align-center text-muted pb-2">
                        ----{t("End")}----
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ToastHOC(ParentsDashboard);

// import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
// import PageHeader from "../../../components/PageHeader";
// import ApiCalling from "../../../network/ApiCalling";
// import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import IconButton from "@mui/material/IconButton";
// import CommentIcon from "@mui/icons-material/Comment";
// import ToastHOC from "../../HOC/ToastHOC";

// const ParentsDashboard = (props) => {
//   console.log(props)
//   const history = useHistory();
//   const [parentData, setParentData] = useState([]);
//   const [childData, setChildData] = useState(null);
//   const [childActivity, setChildActivity] = useState([]);
//   const [cardMediaHeight, setCardMediaHeight] = useState(0);
//   const {setIsToast} = props;

//   const handleChildHandle = (e) => {
//     setChildData(e.target.value);
//   };

//   function handleCardMediaLoad(event) {
//     const { naturalHeight, naturalWidth } = event.target;
//     const aspectRatio = naturalWidth / naturalHeight;
//     setCardMediaHeight(300 / aspectRatio); // set a default height, such as 300px
//   }

//   const parentDashBoard = () => {
//     ApiCalling.apiCallBodyDataGet("parent/dashboard")
//       .then((res) => {
//         console.log("Parent Data::", res.data);
//         setParentData(res.data.data.Child);
//         setChildActivity(res.data.data.Activities);
//         setIsToast("Successfully Loaded");

//       })
//       .catch((error) => {
//         console.log("ERROR", error);
//       });
//   };
//   useEffect(() => {
//     parentDashBoard();

//   }, []);

//   return (
//     <>
//       <div
//         onClick={() => {
//           document.body.classList.remove("offcanvas-active");
//         }}>
//         <div>
//           <div className="container-fluid">
//             <PageHeader
//               HeaderText="Dashboard"
//               Breadcrumb={[{ name: "Dashboard" }]}
//             />
//             <div className="d-wrapper">
//               <hr />

//               {parentData.length > 0 ? (
//                 <>
//                   <div >

//                   <select
//                         className="form-select"
//                         style={{display:"flex", width: "20%", float: "right" }}
//                         value={childData}
//                         onChange={handleChildHandle}
//                       >
//                         {/* <option selected disabled value="">Select Child</option> */}
//                         {parentData.map((item) => {
//                           return (
//                             <>
//                               <option value={item.Student.id}>
//                                 {item.Student.first_name +
//                                   " " +
//                                   item.Student.last_name}
//                               </option>
//                             </>
//                           );
//                         })}
//                       </select>

//                     <div
//                     style={{
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       flexDirection: "column",
//                     }}
//                     >
//                       {childActivity.map((data) => {
//                         console.log("image",data.image)
//                         return (
//                           <>
//                             <Card
//                               style={{
//                                 width: "35%",
//                                 marginBottom: "2%",
//                                 boxShadow: "2px 2px 2px 2px #D0D0D0	",
//                               }}
//                             >

//                               {data.image ? (
//                                 <>
//                                   <CardMedia
//                                     component="img"
//                                     src={data.image}
//                                     onLoad={handleCardMediaLoad}
//                                     style={{ height: cardMediaHeight }}
//                                   />
//                                 </>
//                               ) : (
//                                 ""
//                               )}

//                               <CardContent>
//                                 <Typography
//                                   gutterBottom
//                                   component="div"
//                                   style={{ fontWeight: 700 }}
//                                 >
//                                   {data.Category.title}
//                                 </Typography>

//                                 <Typography
//                                   variant="body2"
//                                   color="text.secondary"
//                                 >
//                                   {data.description}
//                                 </Typography>
//                                 <Typography
//                                   variant="body2"
//                                   color="text.secondary"
//                                 >
//                                   {data.isduration === true ? (
//                                     <>
//                                       <div
//                                         style={{
//                                           display: "flex",
//                                           justifyContent: "space-evenly",
//                                           float: "right",
//                                         }}
//                                       >
//                                         <Typography>
//                                           Start Time:{data.start_time}-
//                                         </Typography>
//                                         <Typography>
//                                           End Time:{data.end_time}
//                                         </Typography>
//                                       </div>
//                                       <br />
//                                     </>
//                                   ) : (
//                                     <>
//                                       <div style={{ float: "right" }}>
//                                         Date:
//                                         {new Date(
//                                           data.created_on
//                                         ).toLocaleString()}
//                                       </div>
//                                       <br />
//                                     </>
//                                   )}
//                                 </Typography>
//                                 <Typography
//                                   variant="p"
//                                   color="text.secondary"
//                                   style={{
//                                     color: "gray",
//                                     fontStyle: "italic",
//                                     fontSize: "12px",
//                                   }}
//                                 >
//                                   Feed By :{data.created_by}
//                                 </Typography>
//                               </CardContent>
//                               <CardActions disableSpacing>
//                                 <IconButton
//                                   aria-label="add to favorites"
//                                 >
//                                   <FavoriteIcon/>
//                                 </IconButton>
//                                 <IconButton aria-label="comment">
//                                   <CommentIcon />
//                                 </IconButton>
//                               </CardActions>
//                             </Card>
//                           </>
//                         );
//                       })}
//                     </div>
//                   </div>
//                 </>
//               ) : (
//                 <>
//                   <div className="row my-3">
//                     <div className="col-lg-4 offset-lg-4 text-center my-4">
//                       <h1> Welcome </h1>{" "}
//                       <p> Get started by adding your child. </p>
//                       <div> </div>
//                       <button
//                         className="btn btn-primary custom-primary"
//                         to="addChild"
//                         onClick={() =>
//                           history.push("/addChild")
//                         }
//                       >
//                         Add a Child
//                       </button>
//                     </div>
//                   </div>
//                   <div className=" row text-center my-4">
//                     <h4 className="text-center">
//                       <i className="fa-solid fa-download mx-2"> </i>Download the
//                       app!
//                     </h4>
//                     <div className="text-app">
//                       <p className="lh-2">
//                         Take advantage of valuable features including daily{" "}
//                         <br />
//                         check - ins, the ability to share photos, access daily
//                         activities, messaging <br /> capabilities, and a
//                         convenient calendar.
//                       </p>
//                     </div>
//                     <div
//                       className="wrapper"
//                       style={{
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         padding: "25px",
//                       }}
//                     >
//                       <div className="col-lg-3">
//                         <div className="android-app card h-100 p-3">
//                           <i
//                             className="fa-brands fa-android "
//                             style={{ fontSize: "50px" }}
//                           ></i>
//                           <p>
//                             Download on the <br />{" "}
//                             <strong> Google Play </strong>
//                           </p>
//                         </div>
//                       </div>
//                       <div className="col-lg-3">
//                         <div className="ios-app card h-100 p-3">
//                           <i
//                             className="fa-brands fa-apple"
//                             style={{ fontSize: "50px" }}
//                           ></i>
//                           <p>
//                             Download on the <br /> <strong> AppStore </strong>
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ToastHOC(ParentsDashboard);

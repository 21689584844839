import React, { useEffect, useState } from "react";
import ApiCalling from "../../../network/ApiCalling";
import Select from "react-dropdown-select";
import checkIn from "../../../assets/Icon/Group 691314505.svg";
import staffCheckIn from "../../../assets/Icon/Group 691314505 (1).svg";
import leaveImage from "../../../assets/Icon/Group 691314505 (2).svg";
import StudentCountCard from "./StudentCountCard";
import UpcomingEventCard from "./UpcomingEventCard";
import { useTranslation } from "react-i18next";
import ReactEcharts from "echarts-for-react";
import moment from "moment/moment";
import CustomInvoiceDetailsCard from "./CustomInvoiceDetailsCard";
import CustomStepper from "./CustomStepper";
import NewsFeedOverview from "./NewsFeedOverview";
import DotLoader from "../../../components/common/DotLoader";
import Util from "../../../Util";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Home() {
  const history = useHistory();
  const user = Util.getUser();
  const school = Util.getSelectedSchool();
  const SwitchSchool = useSelector((state) => state.SwitchSchoolReducer);
  const { t } = useTranslation();

  const [isCheckInLeaveLoading, setIsCheckInLeaveLoading] = useState(false);
  const [isPercentageStudentLoading, setIsPercentageStudentLoading] =
    useState(false);
  const [isPercentageStaffLoading, setIsPercentageStaffLoading] =
    useState(false);
  const [isOpenInvoiceLoading, setIsOpenInvoiceLoading] = useState(false);
  const [isPastPaymentLoading, setIsPastPaymentLoading] = useState(false);
  const [isUpcomingEventLoading, setIsUpcomingEventLoading] = useState(false);
  const [isNewsFeedLoading, setIsNewsFeedLoading] = useState(false);
  const [isDailyActivityLoading, setIsDailyActivityLoading] = useState(false);
  const [gradeList, setGradeList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [classId, setClassId] = useState(0);
  const [gradeId, setGradeId] = useState(0);

  const [getCheckInLeaveDetails, setCheckInLeaveDetails] = useState(null);
  const [getPercentageStudentDetails, setPercentageStudentDetails] =
    useState(null);
  const [getPercentageStaffDetails, setPercentageStaffDetails] = useState(null);
  const [getOpenInvoiceDetails, setOpenInvoiceDetails] = useState(null);
  const [getPastPaymentsDetails, setPastPaymentsDetails] = useState([]);
  const [getUpcomingEventsDetails, setUpcomingEventsDetails] = useState([]);
  const [getNewsFeedDetails, setNewsFeedDetails] = useState(null);
  const [getDailyActivityDetails, setDailyActivityDetails] = useState([]);
  const [newsFeedValue, setNewsFeedValue] = useState("today");
  const [dailyActivityValue, setDailyActivityValue] = useState("today");
  const [totalActivity, setTotalActivity] = useState();

  const [newUser, setNewUser] = useState(false);

  useEffect(() => {
    if (user?.user_Schools?.length === 0) {
      setNewUser(true);
    }
  }, []);

  const activityDuration = [
    { value: "today", name: `${t("Today")}` },
    { value: "yesterday", name: `${t("Yesterday")}` },
    { value: "thisweek", name: `${t("This Week")}` },
    // { value: "lastmonth", name: "LastMonth" },
  ];

  const activeStudents =
    (Util.isValidArray(getPercentageStudentDetails) &&
      getPercentageStudentDetails.map((item) => {
        const percentage =
          item.activeCount && school?.capacity
            ? (item.activeCount / school?.capacity) * 100
            : 0;
        return { count: item.activeCount, percentage };
      })) ||
    [];
  const inActiveStudents =
    (Util.isValidArray(getPercentageStudentDetails) &&
      getPercentageStudentDetails.map((item) => {
        const percentage =
          item.inactiveCount && school?.capacity
            ? (item.inactiveCount / school?.capacity) * 100
            : 0;
        return { count: item.inactiveCount, percentage };
      })) ||
    [];
  const activeStaff =
    (Util.isValidArray(getPercentageStaffDetails) &&
      getPercentageStaffDetails.map((item) => {
        const percentage =
          item.activeCount && school?.staff_capacity
            ? (item.activeCount / school?.staff_capacity) * 100
            : 0;
        return { count: item.activeCount, percentage };
      })) ||
    [];
  const inActiveStaff =
    (Util.isValidArray(getPercentageStaffDetails) &&
      getPercentageStaffDetails?.map((item) => {
        const percentage =
          item.inactiveCount && school?.staff_capacity
            ? (item.inactiveCount / school?.staff_capacity) * 100
            : 0;
        return { count: item.inactiveCount, percentage };
      })) ||
    [];

  // const cashPayment =
  //   Util.isValidArray(getPastPaymentsDetails) &&
  //   getPastPaymentsDetails?.map((item) => item.amount / totalCash);
  const chartColors = ["#4070FA", "#EAA12E", "#15A84B", "#6418C3", "#9FA9B6"];

  const studentOption = {
    xAxis: {
      axisLine: {
        show: false,
      },
      data: [
        `${"Jan"}`,
        `${"Feb"}`,
        `${"Mar"}`,
        `${"Apr"}`,
        `${"May"}`,
        `${"Jun"}`,
        `${"Jul"}`,
        `${"Aug"}`,
        `${"Sep"}`,
        `${"Oct"}`,
        `${"Nov"}`,
        `${"Dec"}`,
      ],
    },
    yAxis: {
      axisLine: {
        show: false,
      },
      min: 0,
      max: 100,
      interval: 20,
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
        },
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
      formatter: function (params) {
        const dataIndex = params[0].dataIndex;
        let tooltipText = params[0].name + "<br/>";
        params.forEach(function (item) {
          const count =
            item.seriesName === "Active"
              ? activeStudents[dataIndex].count
              : inActiveStudents[dataIndex].count;
          tooltipText +=
            item.marker +
            item.seriesName +
            ": " +
            count +
            " - " +
            Util.formatNumber(item.value) +
            "%<br/>";
        });
        return tooltipText;
      },
    },
    series: [
      {
        name: "Active",
        type: "bar",
        data: activeStudents.map((item) => item.percentage),
        barWidth: "10px",
        color: "#EAA12E",
        emphasis: { itemStyle: { color: "#EAA12E" } },
      },
      {
        name: "Inactive",
        type: "bar",
        data: inActiveStudents.map((item) => item.percentage),
        barWidth: "10px",
        color: "#E5E7EB",
        emphasis: { itemStyle: { color: "#E5E7EB" } },
      },
    ],
    itemStyle: {
      barBorderRadius: [8, 8, 0, 0],
    },
  };

  const staffOption = {
    xAxis: {
      axisLine: {
        show: false,
      },
      data: [
        `${"Jan"}`,
        `${"Feb"}`,
        `${"Mar"}`,
        `${"Apr"}`,
        `${"May"}`,
        `${"Jun"}`,
        `${"Jul"}`,
        `${"Aug"}`,
        `${"Sep"}`,
        `${"Oct"}`,
        `${"Nov"}`,
        `${"Dec"}`,
      ],
    },
    yAxis: {
      axisLine: {
        show: false,
      },
      min: 0,
      max: 100,
      interval: 20,
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed", // Use dashed line style
        },
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
      formatter: function (params) {
        const dataIndex = params[0].dataIndex;
        let tooltipText = params[0].name + "<br/>";
        params.forEach(function (item) {
          const count =
            item.seriesName === "Active"
              ? activeStaff[dataIndex].count
              : inActiveStaff[dataIndex].count;
          tooltipText +=
            item.marker +
            item.seriesName +
            ": " +
            count +
            " - " +
            Util.formatNumber(item.value) +
            "%<br/>";
        });
        return tooltipText;
      },
    },
    series: [
      {
        name: "Active",
        type: "bar",
        data: activeStaff.map((item) => item.percentage),
        barWidth: "10px",
        color: "#4070FA",
        emphasis: { itemStyle: { color: "#4070FA" } },
      },
      {
        name: "Inactive",
        type: "bar",
        data: inActiveStaff.map((item) => item.percentage),
        barWidth: "10px",
        color: "#E5E7EB",
        emphasis: { itemStyle: { color: "#E5E7EB" } },
      },
    ],
    itemStyle: {
      barBorderRadius: [8, 8, 0, 0],
    },
  };
  const invoiceOption = {
    title: {
      text: "",
      left: "center",
      top: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: " {d}%",
    },
    series: [
      {
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },

        data: [
          {
            value: getOpenInvoiceDetails?.past_due,
            itemStyle: { color: "#15A84B" },
          },
          {
            value: getOpenInvoiceDetails?.unpaid,
            itemStyle: { color: "#EAA12E" },
          },
        ],
      },
    ],
  };
  const paymentOption = {
    title: {
      text: "",
      left: "center",
      top: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: "{d}%",
    },
    series: [
      {
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: getPastPaymentsDetails,
      },
    ],
  };
  const changeRoom = (data) => {
    setClassId(data[0].id || 0);
  };
  const changeGrade = (data) => {
    setGradeId(data[0].id);
    setClassId(0);
  };
  const changeNewsFeed = (data) => {
    setNewsFeedValue(data[0].value);
  };
  const changeActivity = (data) => {
    setDailyActivityValue(data[0].value);
  };
  const checkInLeaveDetails = () => {
    setIsCheckInLeaveLoading(true);
    const data = {
      grade_id: gradeId,
      room_id: classId,
    };
    ApiCalling.apiCallBodyDataPost("dashboard/fetch/checkin-leave", data)
      .then((res) => {
        setCheckInLeaveDetails(res.data);
        setIsCheckInLeaveLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const percentageStudentDetails = () => {
    setIsPercentageStudentLoading(true);
    const data = {
      grade_id: gradeId,
      room_id: classId,
    };
    ApiCalling.apiCallBodyDataPost("dashboard/fetch/percentage-student", data)
      .then((res) => {
        setIsPercentageStudentLoading(false);
        setPercentageStudentDetails(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const percentageStaffDetails = () => {
    setIsPercentageStaffLoading(true);
    const data = {
      grade_id: gradeId,
      room_id: classId,
    };
    ApiCalling.apiCallBodyDataPost("dashboard/fetch/percentage-staff", data)
      .then((res) => {
        setIsPercentageStaffLoading(false);
        setPercentageStaffDetails(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const openInvoiceDetails = () => {
    setIsOpenInvoiceLoading(true);
    const data = {
      grade_id: gradeId,
      room_id: classId,
    };
    ApiCalling.apiCallBodyDataPost("dashboard/fetch/open-invoices", data)
      .then((res) => {
        setIsOpenInvoiceLoading(false);
        setOpenInvoiceDetails(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const pastPaymentsDetails = () => {
    setIsPastPaymentLoading(true);
    const data = {
      grade_id: gradeId,
      room_id: classId,
    };
    ApiCalling.apiCallBodyDataPost("dashboard/fetch/past-payments", data)
      .then((res) => {
        let totalPayment;
        let modifiedData;
        if (Array.isArray(res.data)) {
          totalPayment = res.data.reduce((a, v) => (a = a + v.amount), 0);
          modifiedData = res.data?.map((d, index) => {
            const pr = (d.amount / totalPayment) * 100;
            return {
              value: pr,
              itemStyle: { color: chartColors[index] },
              data: d,
              name: d.method,
            };
          });
        } else {
          console.error("res.data is not an array");
        }
        setIsPastPaymentLoading(false);
        setPastPaymentsDetails(modifiedData);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const upcomingEventDetails = () => {
    setIsUpcomingEventLoading(true);
    const data = {
      grade_id: gradeId,
      room_id: classId,
    };
    ApiCalling.apiCallBodyDataPost("dashboard/fetch/upcoming-events", data)
      .then((res) => {
        setIsUpcomingEventLoading(false);
        setUpcomingEventsDetails(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const newsFeedDetails = () => {
    setIsNewsFeedLoading(true);
    const data = {
      grade_id: gradeId,
      room_id: classId,
      period: newsFeedValue,
    };
    ApiCalling.apiCallBodyDataPost("dashboard/fetch/news-feed", data)
      .then((res) => {
        setIsNewsFeedLoading(false);
        setNewsFeedDetails(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getDailyActivity = () => {
    setIsDailyActivityLoading(true);
    const data = {
      grade_id: gradeId,
      room_id: classId,
      period: dailyActivityValue,
    };
    ApiCalling.apiCallBodyDataPost("dashboard/fetch/daily-activity", data)
      .then((res) => {
        setIsDailyActivityLoading(false);
        setTotalActivity(res.data.total_post);
        const colors = ["#4070FA", "#EAA12E", "#15A84B", "#6418C3", "#9FA9B6"];
        const updatedArray = res.data?.countByCategory.map((item, index) => ({
          ...item,
          backgroundColor: colors[index % colors.length],
        }));
        setDailyActivityDetails(updatedArray);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getClassList = async () => {
    ApiCalling.apiCallBodyDataGet("classroom/list").then((res) => {
      const modifiedClassList = res?.data
        ?.filter((g) => gradeId == 0 || g.Grade.id == gradeId)
        .map((grade) => ({
          id: grade.id,
          name: `${grade.Grade.title}-${grade.name}`,
        }));
      setClassList(modifiedClassList);
    });
  };
  const getGradeList = async () => {
    ApiCalling.apiCallBodyDataGet("grade/list").then((res) => {
      if (Array.isArray(res?.data)) {
        setGradeList(res?.data);
      } else {
        setGradeList([]);
      }
    });
  };
  useEffect(() => {
    getGradeList();
    getClassList();
  }, []);
  useEffect(() => {
    setClassId(0);
    getClassList();
  }, [gradeId]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          checkInLeaveDetails(),
          percentageStudentDetails(),
          percentageStaffDetails(),
          openInvoiceDetails(),
          pastPaymentsDetails(),
          upcomingEventDetails(),
          getDailyActivity(),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [gradeId, classId]);
  useEffect(() => {
    const fetchNewsFeed = async () => {
      try {
        await newsFeedDetails();
      } catch (error) {
        console.error("Error fetching news feed:", error);
      }
    };

    fetchNewsFeed();
  }, [gradeId, newsFeedValue, classId]);

  useEffect(() => {
    const fetchActivity = async () => {
      try {
        await getDailyActivity();
      } catch (error) {
        console.error("Error fetching news feed:", error);
      }
    };

    fetchActivity();
  }, [gradeId, dailyActivityValue, classId]);

  const totalPost =
    Util.isValidData(getNewsFeedDetails) &&
    getNewsFeedDetails?.announcement +
      getNewsFeedDetails?.post +
      getNewsFeedDetails?.events;
  useEffect(() => {
    if (SwitchSchool) {
      const fetchData = async () => {
        try {
          await Promise.all([
            checkInLeaveDetails(),
            percentageStudentDetails(),
            percentageStaffDetails(),
            openInvoiceDetails(),
            pastPaymentsDetails(),
            upcomingEventDetails(),
            newsFeedDetails(),
            getDailyActivity(),
          ]);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [SwitchSchool]);

  return (
    <div>
      <div className="container-fluid mb-3">
        <div className="body">
          {!newUser ? (
            <>
              <div className="row p-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <div className="extra-large-text">
                      {t("Welcome")} {user?.firstName}
                    </div>
                    <div className="text-secondary">
                      {!isCheckInLeaveLoading ? (
                        moment(getCheckInLeaveDetails?.date).format(
                          "dddd, DD YYYY"
                        )
                      ) : (
                        <DotLoader />
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-4">
                    <Select
                      searchable={false}
                      className="select-custom select-custom-2 text-nowrap"
                      placeholder="Grade"
                      options={[{ id: 0, title: "All" }, ...gradeList]}
                      onChange={(values) => changeGrade(values)}
                      style={{ width: "100%" }}
                      labelField="title"
                      values={[{ id: 0, title: "Grade" }]}
                      valueField="id"
                    />

                    <Select
                      searchable={false}
                      className="select-custom select-custom-2 text-nowrap"
                      placeholder="Rooms"
                      options={[{ id: 0, name: "All" }, ...classList]}
                      onChange={(values) => changeRoom(values)}
                      style={{ width: "100%" }}
                      labelField="name"
                      values={[{ id: 0, name: "Rooms" }]}
                      valueField="id"
                    />
                  </div>
                </div>
              </div>
              <div className="row pl-4 pr-4">
                <div className="col-lg-8 ">
                  <div className="d-flex align-items-center gap-3">
                    <StudentCountCard
                      className={"home-student-card"}
                      titleIcon={checkIn}
                      bgColor={"#FAF5FE"}
                      title={t("Student Checked-in")}
                      count={
                        !isCheckInLeaveLoading ? (
                          getCheckInLeaveDetails?.student_checkin_count?.count
                        ) : (
                          <DotLoader />
                        )
                      }
                      pr={
                        !isCheckInLeaveLoading ? (
                          `${
                            Util.isValidData(
                              getCheckInLeaveDetails?.student_checkin_count
                                ?.percentageChange
                            )
                              ? getCheckInLeaveDetails?.student_checkin_count?.percentageChange.toFixed(
                                  2
                                )
                              : 0
                          }%`
                        ) : (
                          <DotLoader />
                        )
                      }
                      duration={t("From Last Week")}
                      prColor={
                        getCheckInLeaveDetails?.student_checkin_count
                          ?.percentageChange > 0
                          ? "Positive"
                          : "negative"
                      }
                    />

                    <StudentCountCard
                      className={"home-student-card"}
                      titleIcon={leaveImage}
                      bgColor={"#FFFEFA"}
                      title={t("Leave")}
                      count={
                        !isCheckInLeaveLoading ? (
                          getCheckInLeaveDetails?.student_sickleave_count?.count
                        ) : (
                          <DotLoader />
                        )
                      }
                      pr={
                        !isCheckInLeaveLoading ? (
                          `${
                            Util.isValidData(
                              getCheckInLeaveDetails?.student_sickleave_count
                                ?.percentageChange
                            )
                              ? getCheckInLeaveDetails?.student_sickleave_count?.percentageChange.toFixed(
                                  2
                                )
                              : 0
                          }%`
                        ) : (
                          <DotLoader />
                        )
                      }
                      duration={t("From Last Week")}
                      prColor={
                        getCheckInLeaveDetails?.student_sickleave_count
                          ?.percentageChange > 0
                          ? "Positive"
                          : "negative"
                      }
                    />
                  </div>
                  <div className="mt-3">
                    <div className="student-pr-card">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="large-bold-text">{t("Student")}</div>
                        <div className="d-flex align-items-center gap-2">
                          <div
                            className="custom-circle"
                            style={{ backgroundColor: "#EAA12E" }}
                          ></div>
                          <div className="basic-text">{t("Active")}</div>

                          <div
                            className="custom-circle"
                            style={{ backgroundColor: "#E9EAEE" }}
                          ></div>
                          <div className="basic-text">{t("Inactive")}</div>
                        </div>
                      </div>
                      {!isPercentageStudentLoading ? (
                        <ReactEcharts option={studentOption} />
                      ) : (
                        <div className="dot-loader">
                          <DotLoader />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className=" mt-3">
                    <div className="student-pr-card">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="large-bold-text">{t("Staff")}</div>
                        <div className="d-flex align-items-center gap-2">
                          <div
                            className="custom-circle"
                            style={{ backgroundColor: "#4070FA" }}
                          ></div>
                          <div className="basic-text">{t("Active")}</div>

                          <div
                            className="custom-circle"
                            style={{ backgroundColor: "#E9EAEE" }}
                          ></div>
                          <div className="basic-text">{t("Inactive")}</div>
                        </div>
                      </div>
                      {!isPercentageStaffLoading ? (
                        <ReactEcharts option={staffOption} />
                      ) : (
                        <div className="dot-loader">
                          <DotLoader />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-6 col-md-6">
                      <div className="Invoice-card">
                        <div className="large-bold-text">
                          {t("Open Invoice as of today")}
                        </div>

                        <div className="d-flex align-items-center justify-content-center">
                          {!isOpenInvoiceLoading ? (
                            <ReactEcharts
                              option={invoiceOption}
                              className="col-8"
                            />
                          ) : (
                            <div className="dot-loader">
                              <DotLoader />
                            </div>
                          )}

                          <div className="col-4">
                            {getOpenInvoiceDetails?.past_due !== "NaN" && (
                              <CustomInvoiceDetailsCard
                                title={t("Past Due")}
                                count={
                                  !isOpenInvoiceLoading ? (
                                    getOpenInvoiceDetails?.past_due + "%"
                                  ) : (
                                    <DotLoader />
                                  )
                                }
                                bgColor={"#15A84B"}
                              />
                            )}
                            {getOpenInvoiceDetails?.unpaid !== "NaN" && (
                              <CustomInvoiceDetailsCard
                                title={t("Pending")}
                                count={
                                  !isOpenInvoiceLoading ? (
                                    getOpenInvoiceDetails?.unpaid + "%"
                                  ) : (
                                    <DotLoader />
                                  )
                                }
                                bgColor={"#EAA12E"}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="Invoice-card">
                        <div className="large-bold-text">
                          {t("Payments in last 35 days")}
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                          {!isPastPaymentLoading ? (
                            <ReactEcharts
                              option={paymentOption}
                              className="col-8"
                            />
                          ) : (
                            <div className="dot-loader">
                              <DotLoader />
                            </div>
                          )}
                          <div className="col-lg-4 col-md-4">
                            {getPastPaymentsDetails?.map((p, index) => {
                              return (
                                <div key={index}>
                                  <CustomInvoiceDetailsCard
                                    title={p.data.method}
                                    count={p.value.toFixed(2) + "%"}
                                    bgColor={p.itemStyle.color}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 text-start">
                  <div className="upcoming-event-contaier ev-list">
                    <div className="d-flex justify-content-between align-items-center px-2 mt-2">
                      <h4 className="large-bold-text">{t("Upcoming Event")}</h4>
                      <div
                        className="link-text"
                        onClick={() => {
                          history.push("/appcalendar");
                        }}
                      >
                        {t("See All")}
                      </div>
                    </div>
                    {!isUpcomingEventLoading ? (
                      <>
                        {getUpcomingEventsDetails.length > 0 &&
                          getUpcomingEventsDetails
                            .slice(0, 3)
                            .map((ev, index) => {
                              return (
                                <div key={index}>
                                  <UpcomingEventCard
                                    month={moment(ev.dd).format("MMM")}
                                    date={moment(ev.dd).format("DD")}
                                    title={ev.event.title}
                                    description={ev.event.description}
                                    bgColor={ev.event.EventType.color_code}
                                  />
                                </div>
                              );
                            })}
                      </>
                    ) : (
                      <div className="event-dot-loader">
                        <DotLoader />
                      </div>
                    )}
                  </div>

                  <NewsFeedOverview
                    TotalPostCount={
                      !isNewsFeedLoading ? totalPost : <DotLoader />
                    }
                    eventCount={
                      !isNewsFeedLoading ? (
                        getNewsFeedDetails?.events
                      ) : (
                        <DotLoader />
                      )
                    }
                    postCount={
                      !isNewsFeedLoading ? (
                        getNewsFeedDetails?.post
                      ) : (
                        <DotLoader />
                      )
                    }
                    photosCount={
                      !isNewsFeedLoading ? (
                        getNewsFeedDetails?.image
                      ) : (
                        <DotLoader />
                      )
                    }
                    videosCount={
                      !isNewsFeedLoading ? (
                        getNewsFeedDetails?.video
                      ) : (
                        <DotLoader />
                      )
                    }
                    announcementCount={
                      !isNewsFeedLoading ? (
                        getNewsFeedDetails?.announcement
                      ) : (
                        <DotLoader />
                      )
                    }
                  >
                    <div className="home">
                      <Select
                        className="select-custom text-nowrap dropdown-left"
                        placeholder={t("Today")}
                        options={activityDuration}
                        onChange={(values) => changeNewsFeed(values)}
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          color: "#07112B",
                          flexWrap: "nowrap",
                        }}
                        labelField="name"
                        searchable={false}
                        values={[{ value: "today", name: `${t("Today")}` }]}
                        valueField="value"
                      />
                    </div>
                  </NewsFeedOverview>

                  <div className="mt-3 daily-activity-card">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <div className="large-bold-text">
                        {t("Daily Activity")}
                      </div>
                      <Select
                        className="select-custom dropdown-left"
                        placeholder="Today"
                        options={activityDuration}
                        onChange={(values) => changeActivity(values)}
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          color: "#07112B",
                        }}
                        labelField="name"
                        searchable={false}
                        values={[{ value: "today", name: `${t("Today")}` }]}
                        valueField="value"
                      />
                    </div>
                    <div className="text-sm-secondary">{t("Total")}</div>
                    <div className="extensive-large-text">
                      {!isDailyActivityLoading ? totalActivity : <DotLoader />}
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      {getDailyActivityDetails.map((d, index) => {
                        return (
                          <div key={index} className="col-2 p-0">
                            <CustomStepper bgColor={d.backgroundColor} />
                          </div>
                        );
                      })}
                    </div>
                    {getDailyActivityDetails.length > 0 &&
                      getDailyActivityDetails.map((d, index) => {
                        return (
                          <>
                            <div
                              className="d-flex justify-content-between mt-3"
                              key={index}
                            >
                              <div className="d-flex justify-content-center align-items-center gap-2">
                                <div
                                  className="custom-circle"
                                  style={{ backgroundColor: d.backgroundColor }}
                                ></div>
                                <div className="text-lg-secondary">
                                  {!isDailyActivityLoading ? (
                                    d.title
                                  ) : (
                                    <DotLoader />
                                  )}
                                </div>
                              </div>
                              <div className="large-bold-text mr-1">
                                {!isDailyActivityLoading ? (
                                  d.count
                                ) : (
                                  <DotLoader />
                                )}
                              </div>
                            </div>
                            <hr className="dotted-hr " />
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div
              className="d-flex justify-content-center"
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
                bottom: 0,
                top: "200px",
              }}
            >
              <span style={{fontWeight:"bold"}}>
                It looks like your email address isn't linked to any school on
                Classadia. Please verify that you’ve signed up using the email
                address your school used to invite you. If you're unsure,
                contact your school and ask them to send you an invitation.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ApiCalling from "../../../../network/ApiCalling";
import Util from "../../../../Util";
import moment from "moment/moment";
import TagButton from "../../../Dashbord/TagButton";
import {
  greenTagStyle,
  orangeTagStyle,
  redTagStyle,
} from "../../../Dashbord/TagButtonStyle";
import Loader from "../../../../components/common/Loader";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { loadStripe } from "@stripe/stripe-js";
import ToastHOC from "../../../HOC/ToastHOC";
import InvoiceView from "../InvoiceView";
import { InvoiceStatus } from "../../../../Enums";
import { useSelector } from "react-redux";
import { invoiceDownload } from "../../../CommonImage";

function PastInvoices(props) {
  const { setIsToast } = props;
  const { t } = useTranslation();

  const user = Util.getUser();
  const selected = useSelector((state) => state.SelectedStudentReducer);

  const [pastInvoices, setPastInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(5);
  const [isVisibleInvoice, setIsVisibleInvoice] = useState(false);
  const [invoiceId, setInvoiceId] = useState(0);
  const [stripePublishKey, setStripePublishKey] = useState("");
  const STRIPE_PUBLISH_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;

  const school = Util.getSelectedSchool();

  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };
  const getPublishKey = () => {
    ApiCalling.apiCallBodyDataGet(
      "stripe-key/get-key",
      selected.selectedStudent.student.School.id
    )
      .then((res) => {
        if (res.data.status == true) {
          setStripePublishKey(res.data.key);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (Util.isValidData(selected.selectedStudent.student.School.id)) {
      getPublishKey();
    }
  }, [selected.selectedStudent.student.School.id]);
  const handlePay = (id) => {
    const data = {
      invoice_id: id,
    };
    ApiCalling.apiCallBodyDataPost(
      "stripe/create-order",
      data,
      selected.selectedStudent.student.School.id
    ).then(async (res) => {
      //  console.log("strip create-order", res.data);
      if (res.data) {
        const sessionId = res.data.session_id;
        const stripe =
          stripePublishKey.length > 0 && (await loadStripe(stripePublishKey));
        if (sessionId) {
          const result = await stripe
            .redirectToCheckout({
              sessionId: sessionId,
            })
            .then((res) => {
              //  console.log("pay", res);
            });
        } else {
          setIsToast(res.data.raw.message);
        }
      }
    });
  };
  const getPastInvoice = () => {
    setIsLoading(true);
    const data = {
      student_id: selected !== null && selected.selectedStudent.id,
      page: currentPage,
      size: recordPerPage,
    };
    ApiCalling.apiCallBodyDataPost("billing-invoice/student", data, 1)
      .then((res) => {
        if (res.data) {
          setIsLoading(false);
          setPastInvoices(res.data.response);
          setTotalPages(res.data.total_pages);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (Util.isValidData(selected)) {
      getPastInvoice();
    }
  }, [selected, currentPage, recordPerPage]);

  
    const DownloadInvoice = (id) => {
      ApiCalling.apiCallBodyDataGet(`billing-invoice/download-mail/${id}`)
        .then((res) => {
          if (res.data.status == true) {
            window.open(invoiceDownload + res.data.invoice, "_blank");
          } else { 
            setIsToast(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

  return (
    <div className="mt-2">
      {!isLoading ? (
        <table
          className="table "
          style={{ border: "1px solid #e5e7eb", borderRadius: "12px" }}
        >
          <thead style={{ backgroundColor: "#e5e7eb" }}>
            <tr className="text-nowrap">
              <th>{t("Invoice")}</th>
              <th>{t("Fee Type")}</th>
              <th>{t("Due Date")}</th>
              <th>{t("Status")}</th>
              <th>{t("Amount")}</th>
              <th>{t("Balance")}</th>
              <th></th>
            </tr>
          </thead>
          {pastInvoices.length > 0 ? (
            <tbody>
              {pastInvoices.map((r, index) => {
                const LastDate = moment(r.last_payment_date).format(
                  "MM/DD/YYYY"
                );
                const Today = moment(new Date()).format("MM/DD/YYYY");
                return (
                  <>
                    <tr key={index}>
                      <td>{Util.invoiceIdFormat(r.id)}</td>
                      <td>
                        <p style={{ whiteSpace: "pre-line" }}>
                          {Util.getListDisplay(
                            r.types.map((f) => f.Fee_Type),
                            2,
                            "\n"
                          )}
                        </p>
                      </td>
                      <td
                        className={`${
                          LastDate < Today && r.status !== 2 && "text-danger"
                        }`}
                      >
                        {Util.isValidData(r.last_payment_date) ? moment(LastDate).format(Util.getMomentDateTimeFormat(school?.date_format)) : "-"}
                        {LastDate < Today && r.status !== 2 && (
                          <p className="small-text">
                            {moment(Today).diff(moment(LastDate), "days")}{" "}
                            {t("Days Past Due")}
                          </p>
                        )}
                      </td>
                      <td>
                        <TagButton
                          style={"btn btn-secondary"}
                          size={"150px"}
                          title={Util.getInvoiceStatus(r.status)}
                          buttonStyle={
                            r.status == 0
                              ? redTagStyle
                              : r.status == 1
                              ? orangeTagStyle
                              : greenTagStyle
                          }
                          icon="fa fa-circle"
                          iconSize="6px"
                        />
                      </td>
                      <td>{Util.amountFormat(r.total)}</td>
                      <td>{Util.invoiceFormat(r.due)}</td>
                      <td>
                        <div className="dropdown">
                          <button
                            className="btn btn-lg custom-primary"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ color: "#ffffff", padding: "5px, 10px" }}
                          >
                            {t("Action")}
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li style={{cursor:"pointer"}}>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  setIsVisibleInvoice(true);
                                  setInvoiceId(r.id);
                                }}
                              >
                                {t("View")}
                              </a>
                              {r.status !== InvoiceStatus.PAID && (
                                <a
                                  className="dropdown-item"
                                  onClick={() => {
                                    handlePay(r.id);
                                  }}
                                >
                                  {t("Pay")}
                                </a>
                              )}
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  DownloadInvoice(r.id);
                                }}
                              >
                                {t("Download")}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          ) : (
            <tfoot>
              <tr>
                <td colSpan={7}>{t("Not Found!")}</td>
              </tr>
            </tfoot>
          )}
        </table>
      ) : (
        <Loader />
      )}
      <InvoiceView
        show={isVisibleInvoice}
        onClose={() => {
          setIsVisibleInvoice(false);
        }}
        invoiceId={invoiceId}
      />
      {totalPages > 0 && (
        <div className="d-flex justify-content-end mt-2">
          <Stack spacing={2} direction="row" alignItems="center">
            <div
              className={`link-button ${currentPage == 1 ? "disabled" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                handlePreviousClick();
              }}
              style={{ cursor: "pointer" }}
            >
              <KeyboardArrowLeftIcon />
              <span className="basic-text" style={{ fontWeight: "600" }}>
                {t("Previous")}
              </span>
            </div>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, page) => {
                setCurrentPage(page);
              }}
              size="small"
              hideNextButton
              hidePrevButton
            />
            <div
              className={`link-button ${
                currentPage == totalPages ? "disabled" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleNextClick();
              }}
              style={{ cursor: "pointer" }}
            >
              <span className="basic-text" style={{ fontWeight: "600" }}>
                {t("Next")}
              </span>
              <KeyboardArrowRightIcon />
            </div>
          </Stack>
        </div>
      )}
    </div>
  );
}
export default ToastHOC(PastInvoices);

import React, { useEffect, useState } from "react";
import Search from "../../../assets/Icon/Search.svg";
import ApiCalling from "../../../network/ApiCalling";
import Util from "../../../Util";
import {
  imagePost,
  imageStudentList,
  studentInsurance,
} from "../../CommonImage";
import Loader from "../../../components/common/Loader";
import { useTranslation } from "react-i18next";

export default function MediaLibrary(props) {
  const { t } = useTranslation();
  const StudentId = props.props.history.location.studentId;
  const queryParams = new URLSearchParams(props.props.location?.search);
  const studentIdParam = queryParams.get("studentId");

  const [studentMedia, setStudentMedia] = useState([]);
  const [filteredMedia, setFilteredMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const getStudentMedia = () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet(`activity/student-media/${studentIdParam}`)
      .then((res) => {
        setStudentMedia(res.data);
        setFilteredMedia(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    if (studentIdParam > 0) {
      getStudentMedia();
    }
  }, [studentIdParam]);
  function getIconClass(fileName) {
    const isPdf = fileName.match(/\.(pdf)$/i);
    const isDoc = fileName.match(/\.(doc|docx)$/i);
    const isEXL = fileName.match(/\.(xls|xlsx)$/i);

    if (isPdf) {
      return "fa fa-file-o fa-4x text-danger";
    } else if (isDoc) {
      return "fa fa-file-word-o fa-4x text-primary";
    } else if (isEXL) {
      return "fa fa-file-excel-o fa-4x text-success";
    }
  }
  return (
    <div className="my-2">
      <div>
        {!isLoading ? (
          <div className="row m-0">
            {studentMedia.length > 0 || studentMedia !== undefined ? (
              <>
                <div className="input-group mb-4">
                  <input
                    className="form-control search "
                    placeholder="Search"
                    type="text"
                    value={search}
                    onChange={(e) => {
                      // setSearch(e.target.value);
                      setSearch(e.target.value);
                      const searchValue = e.target.value;
                      if (searchValue.trim() === "") {
                        setFilteredMedia(studentMedia);
                      } else {
                        const filteredStaff = studentMedia.filter((s) =>
                          s.image
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        );
                        setFilteredMedia(filteredStaff);
                      }
                    }}
                  />
                  <div className="input-group-prepend">
                    <div className="search-icon">
                      <img src={Search} className="svg-icon" alt="" />
                    </div>
                  </div>
                </div>
                {filteredMedia.map((st, index) => {
                  const isImage = st.image.match(/\.(jpg|jpeg|png|gif)$/i);
                  const img =
                    st.reference == "STUDENT_PROFILE"
                      ? imageStudentList + st.image
                      : st.reference == "STUDENT_MEDIA"
                      ? imagePost + st.image
                      : "";
                  const imgDownload =
                    st.reference == "STUDENT_PROFILE"
                      ? imageStudentList + st.image
                      : st.reference == "STUDENT_MEDIA"
                      ? imagePost + st.image
                      : st.reference == "STUDENT_INSURANCE" &&
                        studentInsurance + st.image;
                  return (
                    <div className="col-lg-4 col-md-6 mb-4" key={index}>
                      <div className="media-container">
                        <div className="media-img-container">
                          {isImage ? (
                            <img src={img} alt="" className="img-fluid" />
                          ) : (
                            <i
                              style={{ marginTop: "65px" }}
                              className={getIconClass(st.image)}
                            />
                          )}
                        </div>
                        <div className="row media-file-container">
                          <div className="col-10 p-0">
                            <div className="large-text wrapTwoLine text-start">
                              {st.image}
                            </div>
                          </div>
                          <div className="col-2 text-end p-0">
                            <div
                              className="dropdown"
                              style={{ cursor: "pointer" }}
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                aria-hidden="true"
                              ></i>

                              <ul
                                className="dropdown-menu plus-icon-dropdown"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li style={{ cursor: "pointer" }}>
                                  <a
                                    className="dropdown-item"
                                    target="_blank"
                                    href={imgDownload}
                                  >
                                    {t("Download")}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div>{t("No Data Found")}</div>
            )}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

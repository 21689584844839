import React, { useState, useEffect } from "react";
import "../../../screens/Dashbord/DashboardCustom.css";
import Select from "react-dropdown-select";
import ApiCalling from "../../../network/ApiCalling";
import { useHistory } from "react-router-dom";
import { Rings } from "react-loader-spinner";
import ToastHOC from "../../HOC/ToastHOC";
import { imageStudentList } from "../../CommonImage";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import Util from "../../../Util";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Search from "../../../assets/Icon/Search.svg";
import TagButton from "../TagButton";
import ModalComponent from "../ModalComponent";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Avatar } from "@mui/material";
import SetBillingPlan from "./SetBillingPlan";
import ManualInvoice from "./AddManualInvoice";
import Loader from "../../../components/common/Loader";

function StudentBalanceList(props) {
  const { t } = useTranslation();
  const getLanguage = Util.getSelectedLanguage();
  const { setIsToast } = props;
  const pendingToast = props.location.pendingToast;
  const [studentList, setStudentList] = useState([]);
  const [classId, setClassId] = useState(0);
  const [classList, setClassList] = useState([]);
  const [gradeId, setGradeId] = useState(0);
  const [gradeList, setGradeList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalStudents, setTotalStudents] = useState(0);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [searchClick, setSearchClick] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [selectedStudentId, setSelectedStudentId] = useState([]);
  const [selectedStudentObjects, setSelectedStudentObjects] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [isVisibleBillPlan, setIsVisibleBillPlan] = useState(false);
  const [isVisibleInvoice, setIsVisibleInvoice] = useState(false);
  const [billingCountId, setBillingCountId] = useState(0);
  const [alertModal, setIsAlertModal] = useState(false);
  const [daysPerWeek, setDaysPerWeek] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [daysPerWeek1, setDaysPerWeek1] = useState([]);

  const billingPlanCountList = [
    { id: 0, name: "Billing plan count" },
    { id: 1, name: "0 plans" },
    { id: 2, name: "One or more plans" },
  ];

  const weekdays = ["M", "TU", "W", "TH", "F"];

  const DaysList = [
    // { value: "all", name: "All" },
    // { value: "", name: "Day Per Week" },
    { value: "Monday", name: "Monday" },
    { value: "Tuesday", name: "Tuesday" },
    { value: "Wednesday", name: "Wednesday" },
    { value: "Thursday", name: "Thursday" },
    { value: "Friday", name: "Friday" },
  ];

  const options = [
    { value: "", name: "Count Per Week" },
    ...Array.from({ length: 6 }, (_, i) => ({
      value: i,
      name: i,
    })),
  ];


  const getClassList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("classroom/list").then((res) => {
      const modifiedClassList = res.data
        .filter((g) => gradeId == 0 || g.Grade.id == gradeId)
        .map((grade) => ({
          id: grade.id,
          name: `${grade.Grade.title}-${grade.name}`,
        }));
      setClassList(modifiedClassList);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  };

  const getGradeList = async () => {
    setIsLoading(true);
    ApiCalling.apiCallBodyDataGet("grade/list").then((res) => {
      setGradeList(res.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    getGradeList();
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);

  useEffect(() => {
    setClassId(0);
    getClassList();
  }, [gradeId]);

  const getStudent = async () => {
    let data = {
      page_size: recordPerPage,
      page_number: currentPage,
      classroom_id: classId,
      grade_id: gradeId,
      str_search: searchClick,
      billing_plan_count: billingCountId,
      weekdays: daysPerWeek1.map((d) => d.value).join(","),
    };
    setIsLoading(true);
    ApiCalling.apiCallBodyDataPost("student/balance", data).then((res) => {
      setStudentList(res.data.data || []);
      setOriginalData(res.data.data || []);
      setTotalPages(res.data.totalPages);
      // setTotalStudents(res.data.total);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
  };

  const CircleLetter = ({ letter, isTrue }) => {
    const circleClassName = `circle ${isTrue ? "circle-checked" : ""}`;
    const letterClassName = `letter ${isTrue ? "letter-checked" : ""}`;
    return (
      <div className={circleClassName}>
        <object className={letterClassName}>{letter[0]}</object>
      </div>
    );
  };

  useEffect(() => {
    getStudent();
  }, [
    recordPerPage,
    currentPage,
    classId,
    gradeId,
    searchClick,
    billingCountId,
    daysPerWeek1
  ]);

  const handleSort = (field) => {
    setSortBy(field);
    setSortOrder(!sortOrder);
  };

  const changeClassRoom = (data) => {
    setCurrentPage(1);
    setClassId(data[0].id);
  };

  const changeGrade = (data) => {
    setCurrentPage(1);
    setClassId(0);
    setGradeId(data[0].id);
  };

  const changeBillingPlanCount = (data) => {
    setCurrentPage(1);
    setBillingCountId(data[0].id);
  };

  const handleAllSelectedStudent = (e) => {
    if (e.target.checked) {
      setSelectedStudentId(studentList.map((item) => item.id));
      setSelectedStudentObjects(studentList);
    } else {
      setSelectedStudentId([]);
      setSelectedStudentObjects([]);
    }
  };

  const StudentChecked = (event) => {
    const id = Number(event.target.value);
    const student = studentList.find((item) => item.id === id);
    const studentName = student.first_name + " " + student.last_name;
    if (event.target.checked) {
      setSelectedStudentId([...selectedStudentId, id]);
      setSelectedStudentObjects([...selectedStudentObjects, student]);
    } else {
      const data = selectedStudentId.filter((s) => s !== id);
      const name = selectedStudentObjects.filter((s) => s !== student);
      setSelectedStudentId(data);
      setSelectedStudentObjects(name);
    }
  };

  const searchClicked = () => {
    setCurrentPage(1);
    setSearchClick(search);
  };
  const EnterHandler = (event) => {
    if (event.key === "Enter") {
      setSearchClick(search);
    }
  };
  const handlePreviousClick = () => {
    if (currentPage !== 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  const getCount = (obj) => {
    const countTrue = Object.values(obj).filter(
      (value) => value === true
    ).length;
    return countTrue;
  };

  const changeDaysPerWeek = (data) => {
    setCurrentPage(1);
    setDaysPerWeek(data);
    setIsLoading(true);
    if (data[0]?.value === "") {
      setStudentList(originalData);
      setIsLoading(false);
    } else {
      originalData.map((item, index) => {
        const dayBooleanValues = {
          M: item.monday,
          TU: item.tuesday,
          W: item.wednesday,
          TH: item.thursday,
          F: item.friday,
        };
        item.dayBooleanValues = dayBooleanValues;
        item.count = Object.values(dayBooleanValues).filter(
          (value) => value === true
        ).length;
        return item;
      });
      const filteredData = originalData.filter((item) => {
        return item.count === data[0]?.value;
      });
      setStudentList(filteredData);
      setIsLoading(false);
    }
  };

  const changeDaysPerWeek1 = (data) =>{
    setCurrentPage(1);
    setDaysPerWeek1(data);
  }

  const customRenderer = ({ props, state, methods }) => {
    const { values } = state;
    const selectedDays = values
      .map((value) => value.name.substring(0, 2))
      .join(", ");
    const hasSelectedValues = selectedDays.length > 0;
    console.log(hasSelectedValues, selectedDays)
    return (
      <>
        <div
          className={` ${
            hasSelectedValues
              ? "react-dropdown-multi-selected"
              : "selected-weekDays"
          }`}
        >
          {selectedDays || props.placeholder}
          {hasSelectedValues && (
            <span
              className={`ml-2`}
              onClick={() => {
                methods.clearAll();
              }}
            >
              ×
            </span>
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <div
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div className="container-fluid">
          <div className="body">
            <div className="row">
              <div className="col-lg col-md-12 p-3">
                <div className="row">
                  <div className="col-md-6 text-start">
                    <h2 className="page-head py-3">{t("Students")}</h2>
                  </div>
                  <div
                    className={`col-md-6 ${
                      getLanguage !== "ar" && "text-right"
                    }`}
                  >
                    <div className="dropdown">
                      <button
                        className="btn btn-lg custom-primary "
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ color: "#ffffff", padding: "5px, 10px" }}
                      >
                        {t("Select an action")}
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (selectedStudentId.length === 0) {
                                setIsToast(t("Select Students"));
                              } else {
                                setIsVisibleBillPlan(true);
                              }
                            }}
                          >
                            {t("Set up a billing Plan")}
                          </a>
                        </li>
                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            className="dropdown-item"
                            onClick={() => {
                              if (selectedStudentId.length === 0) {
                                setIsToast(t("Select Students"));
                              } else {
                                setIsVisibleInvoice(true);
                              }
                            }}
                          >
                            {t("Add a one-time charge")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <SetBillingPlan
                    show={isVisibleBillPlan}
                    onClose={() => {
                      setIsVisibleBillPlan(false);
                    }}
                    studentIds={selectedStudentId}
                    student={selectedStudentObjects}
                    onSave={(msg) => {
                      if (msg) {
                        setIsToast(msg);
                      }
                      setIsVisibleBillPlan(false);
                      setSelectedStudentId([]);
                      setSelectedStudentObjects([]);
                    }}
                    updateData={() => {
                      getStudent();
                    }}
                  />
                  <ManualInvoice
                    show={isVisibleInvoice}
                    onClose={(msg) => {
                      if (msg) {
                        setIsToast(msg);
                      }
                      setIsVisibleInvoice(false);
                    }}
                    studentIds={selectedStudentId}
                    student={selectedStudentObjects}
                    // studentName={selectedStudentName}
                    onSave={(msg) => {
                      if (msg) {
                        setIsToast(msg);
                      }
                      setIsVisibleInvoice(false);
                      getStudent();
                      setSelectedStudentId([]);
                      setSelectedStudentObjects([]);
                    }}
                  />
                </div>
                <div className="row">
                  <div className="d-flex gap-20 align-items-center ml-1 mt-4 pb-2">
                    <div className="custom-search-wrap">
                      <div className="input-group">
                        <input
                          className="form-control search"
                          placeholder="Search Student"
                          type="text"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          onKeyPress={(e) => EnterHandler(e)}
                        />
                        <div className="input-group-prepend">
                          <div className="search-icon" onClick={searchClicked}>
                            <img src={Search} className="svg-icon" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mr-2">
                      <Select
                        className="select-custom"
                        placeholder="Grade"
                        options={[{ id: 0, title: "Grade" }, ...gradeList]}
                        onChange={(values) => changeGrade(values)}
                        style={{ width: "100%" }}
                        labelField="title"
                        values={[{ id: 0, title: "Grade" }]}
                        valueField="id"
                      />
                    </div>

                    <div className="mr-2">
                      <Select
                        className="select-custom"
                        placeholder="Rooms"
                        options={[{ id: 0, name: "Rooms" }, ...classList]}
                        onChange={(values) => changeClassRoom(values)}
                        style={{ width: "100%" }}
                        labelField="name"
                        values={[{ id: 0, name: "Rooms" }]}
                        valueField="id"
                      />
                    </div>

                    <div className="mr-2">
                      <Select
                        className="select-custom"
                        placeholder="Billing plan count"
                        options={[...billingPlanCountList]}
                        onChange={(values) => changeBillingPlanCount(values)}
                        style={{ width: "100%" }}
                        labelField="name"
                        values={[{ id: 0, name: "Billing plan count" }]}
                        valueField="id"
                      />
                    </div>
                    <div className="mr-2">
                      <Select
                        searchable={false}
                        className="select-custom"
                        placeholder="Days Per Week"
                        options={DaysList}
                        onChange={(values) => changeDaysPerWeek1(values)}
                        style={{ width: "100%" }}
                        labelField="name"
                        // values={[{ value: "", name: "Days Per Week" }]}
                        valueField="value"
                        multi
                        contentRenderer={customRenderer}
                      />
                    </div>
                    <div className="mr-2">
                      <Select
                        searchable={false}
                        className="select-custom dropdown-left"
                        placeholder="Count Per Week"
                        options={options}
                        onChange={(values) => changeDaysPerWeek(values)}
                        style={{ width: "100%" }}
                        labelField="name"
                        values={[{ id: 0, name: "Count Per Week" }]}
                        valueField="value"
                        // multi
                        // contentRenderer={customRenderer}
                      />
                    </div>
                  </div>
                </div>
                <div className="table-div mt-2">
                  {!isLoading ? (
                    <>
                      <div className="row">
                        <div className="col-lg-12">
                          <table className="table">
                            <thead>
                              <tr className="text-nowrap">
                                <th
                                  scope="col"
                                  className="check-col"
                                  style={{
                                    paddingRight: "0px",
                                    width: 0,
                                  }}
                                >
                                  <label className="fancy-checkbox element-left">
                                    <input
                                      type="checkbox"
                                      name="allselect"
                                      onChange={handleAllSelectedStudent}
                                      checked={
                                        selectedStudentId?.length ===
                                        studentList?.length
                                      }
                                    />
                                    <span></span>
                                  </label>
                                </th>
                                {/* <th scope="col">
                                      <input
                                        type="checkbox"
                                        name="allselect"
                                        onChange={handleAllSelectedStudent}
                                      />
                                    </th> */}
                                <th
                                  scope="col"
                                  style={{
                                    paddingLeft: "0px",
                                    width: 0,
                                  }}
                                >
                                  {t("Students Name")}
                                  {/* <i
                                        className="fa fa-sort ml-1"
                                        aria-hidden="true"
                                        onClick={() => {
                                          handleSort("student.first_name");
                                        }}
                                      ></i> */}
                                </th>

                                <th scope="col">
                                  {" "}
                                  {t("Student ID")}{" "}
                                  {/* <i
                                        className="fa fa-sort ml-1"
                                        aria-hidden="true"
                                        onClick={() => {
                                          handleSort("student.id");
                                        }}
                                      ></i> */}
                                </th>

                                <th scope="col">
                                  {" "}
                                  {t("Account Balance")}{" "}
                                  {/* <i
                                        className="fa fa-sort ml-1"
                                        aria-hidden="true"
                                        onClick={() => {
                                          handleSort("student.credit");
                                        }}
                                      ></i> */}
                                </th>
                                {/* <th scope="col">
                                      {" "}
                                      {t("Due")}{" "}
                                      <i
                                        className="fa fa-sort ml-1"
                                        aria-hidden="true"
                                        onClick={() => {
                                          handleSort("student.due");
                                        }}
                                      ></i>
                                    </th> */}
                                <th scope="col">
                                  {" "}
                                  {t("Billing Plans")}{" "}
                                  {/* <i
                                        className="fa fa-sort ml-1"
                                        aria-hidden="true"
                                        onClick={() => {
                                          handleSort("student.total_plans");
                                        }}
                                      ></i> */}
                                </th>
                                <th scope="col">Days Per Week</th>
                                <th scope="col">Days Per Week Count</th>
                              </tr>
                            </thead>

                            {studentList.length > 0 ? (
                              <tbody>
                                {studentList.map((item, index) => {
                                  const dayBooleanValues = {
                                    M: item.monday,
                                    TU: item.tuesday,
                                    W: item.wednesday,
                                    TH: item.thursday,
                                    F: item.friday,
                                  };
                                  const remainCount =
                                    Number(item.credit) - Number(item.due);
                                  return (
                                    <tr
                                      key={item.id}
                                      style={{
                                        backgroundColor: `${
                                          selectedStudentId.includes(item.id)
                                            ? "rgba(100, 24, 195, 0.04)"
                                            : ""
                                        }`,
                                      }}
                                    >
                                      <td>
                                        <label className="fancy-checkbox element-left">
                                          <input
                                            type="checkbox"
                                            name={item.first_name}
                                            onChange={StudentChecked}
                                            value={item.id}
                                            checked={selectedStudentId.includes(
                                              item.id
                                            )}
                                          />
                                          <span></span>
                                        </label>
                                      </td>

                                      <td>
                                        <div className="d-flex align-items-center">
                                          {item.photo ? (
                                            <img
                                              className="rounded-circle "
                                              style={{
                                                width: "40px",
                                                height: "40px",
                                              }}
                                              src={
                                                imageStudentList + item.photo
                                              }
                                              alt=""
                                            />
                                          ) : (
                                            <Avatar
                                              aria-label="recipe"
                                              className="avatar-font"
                                            >
                                              {item.first_name.toUpperCase()[0] +
                                                item.last_name.toUpperCase()[0]}
                                            </Avatar>
                                          )}
                                          <a
                                            className="ml-2"
                                            onClick={() => {
                                              history.push({
                                                pathname:
                                                  "/student-bill-details",
                                                student: item,
                                              });
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {item.first_name} {item.last_name}
                                            <div>
                                              {(Util.isValidData(
                                                item?.grade?.title
                                              ) ||
                                                Util.isValidData(
                                                  item?.classroom?.name
                                                )) && (
                                                <span className="small-text">
                                                  {item?.grade?.title +
                                                    " " +
                                                    item?.classroom?.name}
                                                </span>
                                              )}
                                            </div>
                                          </a>
                                        </div>
                                      </td>
                                      <td>
                                        <span>
                                          {Util.studentIdFormat(item.id)}
                                        </span>
                                      </td>

                                      <td>{Util.balanceFormat(remainCount)}</td>

                                      <td>
                                        {item.total_plans === 0 ? (
                                          <a
                                            href="#"
                                            className="link-button"
                                            style={{ fontWeight: 600 }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setIsVisibleBillPlan(true);
                                              setSelectedStudentId([item.id]);
                                              setSelectedStudentObjects([item]);
                                            }}
                                          >
                                            <i
                                              className="fa fa-plus mr-1"
                                              aria-hidden="true"
                                            ></i>
                                            {t("Create plan")}
                                          </a>
                                        ) : item.total_plans > 2 ? (
                                          `${item.total_plans} Plans`
                                        ) : (
                                          `${item.total_plans} Plan`
                                        )}
                                      </td>
                                      <td>
                                        <div className="circle-container">
                                          {weekdays.map((letter, index) => (
                                            <CircleLetter
                                              key={index}
                                              letter={letter}
                                              isTrue={dayBooleanValues[letter]}
                                            />
                                          ))}
                                        </div>
                                      </td>
                                      <td>{getCount(dayBooleanValues)}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tfoot>
                                <tr>
                                  <td colSpan={6}>
                                    {t(
                                      "No Students Found! Please recheck the filter or Create One"
                                    )}
                                  </td>
                                </tr>
                              </tfoot>
                            )}
                          </table>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Loader />
                  )}
                  {totalPages > 0 && (
                    <div className="row m-0 table-footer border-top p-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-2">
                          <div className="basic-text">
                            {t("Students Per Page")}
                          </div>
                          <div>
                            <select
                              name=""
                              id=""
                              className="select-dropdown custom-input"
                              value={recordPerPage}
                              onChange={(e) => {
                                setRecordPerPage(e.target.value);
                              }}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <Stack
                            spacing={2}
                            direction="row"
                            alignItems="center"
                          >
                            <div
                              className={`link-button ${
                                currentPage == 1 ? "disabled" : ""
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                handlePreviousClick();
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <KeyboardArrowLeftIcon />
                              <span className="basic-bold-text">
                                {t("Previous")}
                              </span>
                            </div>
                            <Pagination
                              count={totalPages}
                              page={currentPage}
                              onChange={(event, page) => {
                                setCurrentPage(page);
                              }}
                              size="small"
                              hideNextButton
                              hidePrevButton
                            />
                            <div
                              className={`link-button ${
                                currentPage == totalPages ? "disabled" : ""
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleNextClick();
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <span className="basic-bold-text">
                                {t("Next")}
                              </span>
                              <KeyboardArrowRightIcon />
                            </div>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ToastHOC(StudentBalanceList);

import React, { useEffect, useState } from "react";
import Logo from "../../components/common/Logo";
import { useHistory } from "react-router-dom";
import ApiCalling from "../../network/ApiCalling";
import ToastHOC from "../HOC/ToastHOC";
import { useTranslation } from "react-i18next";
import Util from "../../Util";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Visibility } from "@material-ui/icons";
import { VisibilityOff } from "@material-ui/icons";

function SetPassword(props) {
  const { t } = useTranslation();
  const pendingToast = props.history.location.pendingToast;
  const { setIsToast } = props;
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [resetError, setResetError] = useState("");
  const [email, setEmail] = useState("");
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [confShowPassword, setConfShowPassword] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      const data = atob(token);
      console.log(data);
      setEmail(data);
    }
  }, [token]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError("");
  };
  const handleSave = async (e) => {
    e.preventDefault();
    setPasswordError("");
    setConfirmPasswordError("");
    let error = false;
    if (password === "") {
      setPasswordError(t("Password is required"));
      error = true;
    } else if (Util.password(password) === false) {
      setPasswordError(t("password must be at least 5 characters long"));
      error = true;
    }
    if (confirmPassword === "") {
      setConfirmPasswordError(t("confirm Password is required"));
      error = true;
    } else if (Util.password(confirmPassword) === false) {
      setConfirmPasswordError(t("password must be at least 5 characters long"));
      error = true;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError(t("Password & Confirm Password Not Matched"));
      error = true;
    }
    if (!error) {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        setResetError(t("Recaptcha not yet available. Please try again."));
        return;
      }
      console.log(password);
      try {
        const data = {
          email: email,
          password: password,
        };

        ApiCalling.apiCallBodyDataPost("user/setPassword", data)
          .then((res) => {
            if (res.data.status === true) {
              setIsToast(t(res.data.message));
              Util.saveRegisteredUser(res.data.user);
              setTimeout(function () {
                history.push({
                  pathname: "/verifyEmail",
                });
              }, 2000);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log("Error", error);
      }
    }
  };

  useEffect(() => {
    if (pendingToast && pendingToast.length > 0) {
      setIsToast(pendingToast);
    }
  }, []);

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const toggleConfPasswordVisibility = (e) => {
    e.preventDefault();
    setConfShowPassword(!confShowPassword);
  };

  return (
    <div className="theme-cyan">
      <div className="vertical-align-wrap">
        <div className="vertical-align-middle auth-main">
          <div className="auth-box auth-wrapper">
            <Logo />
            <div className="card">
              <div className="header">
                <h1 className="text-center"> {t("Set Your password")}</h1>
              </div>
              <div className="body">
                {resetError && (
                  <div className="text-danger m-2 text-center">
                    {resetError}
                  </div>
                )}
                <form>
                  <div className="row "></div>
                  <br />
                  <div className="row ">
                    <div className="col">
                      <label>{t("New Password")}</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder={t("Password")}
                        value={password}
                        onChange={handlePasswordChange}
                      />
                      <div
                        style={{
                          float: "right",
                          marginTop: "-32px",
                          marginRight: "5px",
                        }}
                      >
                        <button
                          onClick={togglePasswordVisibility}
                          style={{ backgroundColor: "#fff" }}
                        >
                          {showPassword ? (
                            <VisibilityOff fontSize="small" />
                          ) : (
                            <Visibility fontSize="small" />
                          )}
                        </button>
                      </div>
                      {passwordError.length > 0 && (
                        <span className="text-danger">{passwordError}</span>
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="row ">
                    <div className="col">
                      <label>{t("Confirm Password")}</label>
                      <input
                        type={confShowPassword ? "text" : "password"}
                        className="form-control"
                        placeholder={t("Confirm Password")}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                      />
                      <div
                        style={{
                          float: "right",
                          marginTop: "-32px",
                          marginRight: "5px",
                        }}
                      >
                        <button
                          onClick={toggleConfPasswordVisibility}
                          style={{ backgroundColor: "#fff" }}
                        >
                          {confShowPassword ? (
                            <VisibilityOff fontSize="small" />
                          ) : (
                            <Visibility fontSize="small" />
                          )}
                        </button>
                      </div>
                      {confirmPasswordError.length > 0 && (
                        <span className="text-danger">
                          {confirmPasswordError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col">
                      <button
                        className="btn custom-primary btn-primary btn-lg btn-block get-started-btn"
                        type="submit"
                        onClick={handleSave}
                      >
                        {t("Reset")}
                      </button>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4"></div>
                    {/* <div className="col-8">
                      {t("Know your password?")}{" "}
                      <a href="login"> {t("Login")}</a>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ToastHOC(SetPassword);
